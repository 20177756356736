import { Button, Popconfirm, Table, Typography } from "antd";
import Search from "antd/es/input/Search";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteProduct } from "../../../api/api.delete";
import { useGetProducts, useSearchProducts } from "../../../api/api.get";
import { IProduct } from "../../../types/types";
import AddProductModal from "./add-products-modal";
import ViewProductModal from "./view-products-modal";

const { Title } = Typography;

export default function ProductsHomePage() {
  const { id } = useParams();
  const [searchText, setSearchText] = useState<string>("");

  const { data: products, refetch } = useGetProducts(id);

  const { data: searchProducts, refetch: refetchSearch } = useSearchProducts(
    id,
    searchText,
  );
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [deletableProductId, setDeletableProductId] = useState<string>();
  const [viewableProduct, setViewableProduct] = useState<IProduct>();

  const { mutateAsync: deleteAsync } = useDeleteProduct(
    id!!,
    deletableProductId!!,
  );

  const handleDelete = (row: any) => {
    setDeletableProductId(row._id);

    deleteAsync(null)
      .then(() => refetch())
      .catch(console.error);
  };

  const handleView = (row: any) => {
    setViewableProduct(row);
  };

  useEffect(() => {
    if (searchText) {
      refetchSearch();
    }
  }, [searchText]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price: any) => (
        <span>{`${price ? price?.currency + " " + price?.value : ""}`}</span>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => (
        <span>{moment(value).format("DD MMM YYYY")}</span>
      ),
    },

    {
      title: "",
      key: "operationView",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => handleView(row)}>
          Edit
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure to delete this product?"
          onConfirm={() => handleDelete(row)}
          onCancel={() => setDeletableProductId(undefined)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text" color="red">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="body divColumn mx-40 my-40 flex-1"
    >
      <AddProductModal open={isAdd} onCancel={() => setIsAdd(false)} />
      <ViewProductModal
        open={!!viewableProduct}
        onCancel={() => setViewableProduct(undefined)}
        product={viewableProduct}
      />
      <div className="divCenterAlign">
        <Title level={3}>Products</Title>
      </div>
      <div className="divSpread divAlignItemsOnly mt-40">
        <Search
          placeholder="Search by name..."
          onSearch={(value) => setSearchText(value)}
          style={{ width: 200 }}
        />

        <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
          Add Product
        </Button>
      </div>
      <Table
        dataSource={searchText ? searchProducts : products}
        columns={columns}
        pagination={{ pageSize: 100 }}
      />
    </div>
  );
}
