import { DatePicker, Flex, Input, Modal } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateTimesheet } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";

export default function AddTimeSheetModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();

  const { mutateAsync } = useCreateTimesheet(id);

  const formik = useFormik({
    initialValues: {
      hours: "",
      timeSheetDate: "",
      notes: "",
    },
    validationSchema: Yup.object({
      timeSheetDate: Yup.string().required("Please select date"),
      hours: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid hours")
        .required("Please enter hours"),
    }),
    onSubmit: async (values) => {
      mutateAsync({ ...values, hours: +values.hours })
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch(console.error);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Hours"
        open={open}
        onOk={formik.submitForm}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
      >
        <Flex gap={30} vertical>
          <div>
            <DatePicker
              onChange={(value) => formik.setFieldValue("timeSheetDate", value)}
            />

            <ErrorPanel message={formik.errors.timeSheetDate} />
          </div>

          <div>
            <Input placeholder="Hours" {...formik.getFieldProps("hours")} />
            <ErrorPanel message={formik.errors.hours} />
          </div>

          <div>
            <Input placeholder="Notes" {...formik.getFieldProps("notes")} />
          </div>
        </Flex>
      </Modal>
    </form>
  );
}
