import { Flex, Input, Modal, Select } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateRewardLevel } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";

export default function AddRewardLevelModal({
  currency,
  open,
  onCancel,
}: {
  currency: string;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();

  const { mutateAsync } = useCreateRewardLevel(id);
  const { notification, notifySuccess, notifyError } = useNotifications();

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "percent",
      value: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please give a name"),
      type: Yup.string().required("Please select type"),
      value: Yup.string()
        .matches(
          /^(0|[1-9]\d*)(\.\d+)?$/,
          "Please enter numbers only. Do not include % or currency symbols",
        )
        .required("Please enter value"),
    }),
    onSubmit: async (values) => {
      mutateAsync({ ...values, value: +values.value })
        .then(() => {
          notifySuccess();
          formik.resetForm();
          onCancel();
        })
        .catch((err) => {
          console.error(err);
          notifyError();
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add a Reward Level"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
      >
        {notification}
        <Flex gap={30} vertical>
          <div>
            <Input
              placeholder="5% of total"
              {...formik.getFieldProps("name")}
            />
            <ErrorPanel message={formik.errors.name} />
          </div>

          <div>
            <Select
              defaultValue="percent"
              style={{ width: 120 }}
              onChange={(value) => formik.setFieldValue("type", value)}
              options={[
                { value: "flat", label: "Flat" },
                { value: "percent", label: "Percent" },
              ]}
            />
            <ErrorPanel message={formik.errors.type} />
          </div>

          <div>
            <p className="infoMsg">
              {formik.values.type === "percent"
                ? "Add percent value - 5%"
                : `Add points - For ex: 20`}
            </p>
            <Input {...formik.getFieldProps("value")} />
            <ErrorPanel message={formik.errors.value} />
          </div>
        </Flex>
      </Modal>
    </form>
  );
}
