import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  AdminIUser,
  AttachmentData,
  IAggregation,
  IBusinessProfile,
  IEmployeeSalaryTotal,
  IMoneyTransfer,
  IMoneyTransferPayout,
  IMoneyTransferPayoutItemDetails,
  IMoneyTransferStatus,
  IMoneyTransferWebHook,
  IOrganization,
  IOrganizationEmployee,
  IOrganizationExpense,
  IOrganizationInvestment,
  IOrganizationReward,
  IOrganizationRewardLevel,
  IOrganizationSale,
  IPayroll,
  IPayrollCycle,
  IPayrollSchedule,
  IProduct,
  IProfile,
  IProfileDetails,
  IReimbursement,
  IReimbursementAttachment,
  IReimbursementLifecycle,
  ISupportTicket,
  ITotal,
  IUser,
  IUserSearch,
  UserVerificationDetails,
} from "../types/types";
import { EXHUT_BIZ_LOCAL_STORAGE_TOKEN } from "../utils/constants";

export function useGetProfile() {
  return _useGet<IProfile>(`profile`, ["profile"], true);
}

export function useGetAllOrganizations() {
  return _useGet<IBusinessProfile[]>(`organizations`, ["organizations"], true);
}

export function useGetOrganizationById(id?: string) {
  return _useGet<IOrganization>(
    `organizations/${id}`,
    ["organizations" + id],
    !!id,
  );
}

export function useGetProfileOnOrganization(orgId?: string) {
  return _useGet<IProfileDetails>(
    `organizations/${orgId}/profile`,
    ["organizations", orgId, "profile"],
    !!orgId,
  );
}

export function useGetOrganizationExpenses(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<IOrganizationExpense[]>(
    `organizations/${orgId}/expenses?from=${from}&to=${to}`,
    ["organizations", orgId, "expenses"],
    !!orgId && !!from && !!to,
  );
}

export function useGetTimeSheets(orgId?: string, from?: string, to?: string) {
  return _useGet<IOrganizationExpense[]>(
    `organizations/${orgId}/timesheets?from=${from}&to=${to}`,
    ["organizations", orgId, "timesheets"],
    !!orgId && !!from && !!to,
  );
}

export function useGetRewardLevels(orgId?: string) {
  return _useGet<IOrganizationRewardLevel[]>(
    `organizations/${orgId}/rewards-level`,
    ["organizations", orgId, "rewards-level"],
    !!orgId,
  );
}

export function useGetRewards(orgId?: string, from?: string, to?: string) {
  return _useGet<IOrganizationReward[]>(
    `organizations/${orgId}/rewards?from=${from}&to=${to}`,
    ["organizations", orgId, "rewards"],
    !!orgId && !!from && !!to,
  );
}

export function useGetRewardsById(orgId?: string, rid?: string) {
  return _useGet<IOrganizationReward>(
    `organizations/${orgId}/rewards/${rid}`,
    ["organizations", orgId, "rewards", rid],
    !!orgId && !!rid,
  );
}

export function useGetRewardLevelsById(orgId?: string, id?: string) {
  return _useGet<IOrganizationRewardLevel>(
    `organizations/${orgId}/rewards-level/${id}`,
    ["organizations", orgId, "rewards-level", id],
    !!orgId && !!id,
  );
}

export function useGetReimbursements(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<IReimbursement[]>(
    `organizations/${orgId}/reimbursements?from=${from}&to=${to}`,
    ["organizations", orgId, "reimbursements", from, to],
    !!orgId && !!from && !!to,
  );
}

export function useGetReimbursementById(orgId?: string, id?: string) {
  return _useGet<IReimbursement>(
    `organizations/${orgId}/reimbursements/${id}`,
    ["organizations", orgId, "reimbursements", id],
    !!orgId && !!id,
  );
}

export function useGetReimbursementAttachments(orgId?: string, id?: string) {
  return _useGet<IReimbursementAttachment[]>(
    `organizations/${orgId}/reimbursements/${id}/attachments`,
    ["organizations", orgId, "reimbursements", id, "attachments"],
    !!orgId && !!id,
  );
}

export function useGetReimbursementsLifecycle(orgId?: string, id?: string) {
  return _useGet<IReimbursementLifecycle[]>(
    `organizations/${orgId}/reimbursements/${id}/lifecycle`,
    ["organizations", orgId, "reimbursements", id, "lifecycle"],
    !!orgId && !!id,
  );
}

export function useGetTimeSheetsTotal(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<ITotal>(
    `organizations/${orgId}/timesheets/total?from=${from}&to=${to}`,
    ["organizations", orgId, "timesheets", "total"],
    !!orgId && !!from && !!to,
  );
}

export function useGetOrganizationInvestments(orgId?: string) {
  return _useGet<IOrganizationInvestment[]>(
    `organizations/${orgId}/investments`,
    ["organizations", orgId, "investments"],
    !!orgId,
  );
}

export function useGetPayrollSchedule(orgId?: string) {
  return _useGet<IPayrollSchedule>(
    `organizations/${orgId}/payroll-schedules`,
    ["organizations", orgId, "payroll-schedules"],
    !!orgId,
  );
}

export function useGetPayrollCycles(orgId?: string) {
  return _useGet<IPayrollCycle[]>(
    `organizations/${orgId}/payroll-cycles`,
    ["organizations", orgId, "payroll-cycles"],
    !!orgId,
  );
}

export function useGetPayrolls(orgId?: string, cycleId?: string) {
  return _useGet<IPayroll[]>(
    `organizations/${orgId}/payroll-cycles/${cycleId}/payroll`,
    ["organizations", orgId, "payroll-cycles", cycleId, "payroll"],
    !!orgId && !!cycleId,
  );
}

export function useGetOrganizationInvestmentsTotal(
  orgId?: string,
  date?: string,
) {
  return _useGet<ITotal>(
    `organizations/${orgId}/investments/total?date=${date}`,
    ["organizations", orgId, "investments", "total", date],
    !!orgId,
  );
}

export function useGetOrganizationSales(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<IOrganizationSale[]>(
    `organizations/${orgId}/sales?from=${from}&to=${to}`,
    ["organizations", orgId, "sales"],
    !!orgId && !!from && !!to,
  );
}

export function useGetSalesAggregation(orgId?: string, previous?: string) {
  return _useGet<IAggregation[]>(
    `organizations/${orgId}/sales/aggregation?previous=12`,
    ["organizations", orgId, "sales", "aggregation"],
    !!orgId,
  );
}

export function useGetExpensesAggregation(orgId?: string, previous?: string) {
  return _useGet<IAggregation[]>(
    `organizations/${orgId}/expenses/aggregation?previous=12`,
    ["organizations", orgId, "expenses", "aggregation"],
    !!orgId,
  );
}

export function useGetOrganizationEmployees(orgId?: string) {
  return _useGet<IOrganizationEmployee[]>(
    `organizations/${orgId}/employees`,
    ["organizations" + orgId, "employees"],
    !!orgId,
  );
}

export function useGetOrganizationEmployeesSalaryTotal(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<ITotal>(
    `organizations/${orgId}/employees/salary/total?from=${from}&to=${to}`,
    ["organizations" + orgId, "employees", "salary", "total"],
    !!orgId && !!from && !!to,
  );
}

export function useGetOrganizationEmployeesSalary(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<IEmployeeSalaryTotal[]>(
    `organizations/${orgId}/employees/salary?from=${from}&to=${to}`,
    ["organizations" + orgId, "employees", "salary"],
    !!orgId && !!from && !!to,
  );
}

export function useGetOrganizationEmployeesById(
  orgId?: string,
  empId?: string,
) {
  return _useGet<IOrganizationEmployee>(
    `organizations/${orgId}/employees/${empId}`,
    ["organizations" + orgId, "employees", empId],
    !!orgId && !!empId,
  );
}

export function useGetOrganizationEmployeesTotal(orgId?: string) {
  return _useGet<ITotal>(
    `organizations/${orgId}/employees/total`,
    ["organizations" + orgId, "employees", "total"],
    !!orgId,
  );
}

export function useGetOrganizationSalesTotal(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<ITotal>(
    `organizations/${orgId}/sales/total?from=${from}&to=${to}`,
    ["organizations" + orgId, "sales", "total"],
    !!orgId && !!from && !!to,
  );
}

export function useGetOrganizationExpensesTotal(
  orgId?: string,
  from?: string,
  to?: string,
) {
  return _useGet<ITotal>(
    `organizations/${orgId}/expenses/total?from=${from}&to=${to}`,
    ["organizations" + orgId, "expenses", "total"],
    !!orgId && !!from && !!to,
  );
}

export function useGetSession(enabled: boolean) {
  return _useGetAuthBE<IUser>("session", ["session"], enabled);
}

export function useSearchCustomers(phone?: string, enabled?: boolean) {
  return _useGet<IUserSearch>(
    `search/customers?phone=${phone}`,
    ["search", "customers", phone],
    false,
  );
}

// admin only API
export function useGetUserByUsernameByAdmin(username?: string) {
  return _useGetAuthBE<AdminIUser>(
    `admin/users?username=${username}`,
    ["admin", "users", username],
    !!username,
  );
}

export function useGetAttachmentsForUser(username?: string) {
  return _useGet<AttachmentData[]>(
    `admin/attachments?username=${username}`,
    ["admin", "attachments", username],
    !!username,
  );
}

export function useGetProducts(orgId?: string) {
  return _useGetProducts<IProduct[]>(
    `organizations/${orgId}/products`,
    ["products"],
    !!orgId,
  );
}

export function useSearchProducts(orgId?: string, searchText?: string) {
  return _useGetProducts<IProduct[]>(
    `organizations/${orgId}/products/search?name=${searchText}`,
    ["products", "search", searchText],
    !!orgId && !!searchText,
  );
}

export function useSearchProductsByBarcode(orgId?: string, barcodeId?: string) {
  return _useGetProducts<IProduct>(
    `organizations/${orgId}/products/barcode?code=${barcodeId}`,
    ["products", "barcode", barcodeId],
    !!orgId && !!barcodeId,
  );
}

function _useGetProducts<T1>(
  path: string,
  keys: (string | undefined | null)[],
  enabled?: boolean,
  authenticated: boolean = true,
) {
  return _useGet<T1>(
    path,
    keys,
    enabled,
    authenticated,
    process.env.REACT_APP_PRODUCTS_URL,
  );
}

function _useGetAuthBE<T1>(
  path: string,
  keys: (string | undefined | null)[],
  enabled?: boolean,
  authenticated: boolean = true,
) {
  return _useGet<T1>(
    path,
    keys,
    enabled,
    authenticated,
    process.env.REACT_APP_AUTH_BE_URL,
  );
}

function _useGet<T1>(
  path: string,
  keys: (string | undefined | null)[],
  enabled?: boolean,
  authenticated: boolean = true,
  host?: string,
) {
  let headers: any = {};

  const hostUrl = host ?? process.env.REACT_APP_BE_URL;

  if (authenticated) {
    headers["bearer"] = localStorage.getItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN);
    headers["authorization"] = localStorage.getItem(
      EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
    );
  }

  return useQuery<T1, Error>({
    queryFn: async () => {
      return axios
        .get(`${hostUrl}/${path}`, {
          headers,
        })
        .then((resp) => resp.data);
    },
    queryKey: keys,
    enabled,
    retry: 0,
  });
}

export function useGetMySupportTickets() {
  return _useGet<ISupportTicket[]>("support", ["support"], true, true);
}

export function useGetMySupportTicketsById(id?: string) {
  return _useGet<ISupportTicket[]>("support", ["support", id], !!id);
}

// --------------backend services ------------------------------------------

export function useGetAllMoneyTransfersForAdmin() {
  return _useGetExhutBackendService<IMoneyTransfer[]>(
    "admin/money-transfers",
    ["admin-money-transfers"],
    true,
  );
}

export function useGetMoneyTransferByIdForAdmin(id?: string) {
  return _useGetExhutBackendService<IMoneyTransfer>(
    "admin/money-transfers/" + id,
    ["admin-money-transfers", id],
    !!id,
  );
}

export function useGetMoneyTransferPayoutsForAdmin(transferId?: string) {
  return _useGetExhutBackendService<IMoneyTransferPayout>(
    "money-transfers/" + transferId + "/payouts",
    ["admin-money-transfers", transferId, "payouts"],
    !!transferId,
  );
}

export function useGetMoneyTransferPayoutsItemDetailsForAdmin(
  transferId?: string,
) {
  return _useGetExhutBackendService<IMoneyTransferPayoutItemDetails>(
    "admin/money-transfers/" + transferId + "/payouts-item-status",
    ["admin-money-transfers", transferId, "payouts-item-status"],
    !!transferId,
  );
}

export function useGetMoneyTransferStatusesByIdForAdmin(id?: string) {
  return _useGetExhutBackendService<IMoneyTransferStatus[]>(
    "admin/money-transfers/" + id + "/status",
    ["admin-money-transfers", id, "status"],
    !!id,
  );
}

export function useGetMoneyTransferWebHooks(orderId?: string) {
  return _useGetExhutBackendService<IMoneyTransferWebHook[]>(
    "admin/hooks/paypal/" + orderId,
    ["admin-money-transfers-hooks", orderId],
    !!orderId,
  );
}

function _useGetExhutBackendService<T1>(
  path: string,
  keys: (string | undefined | null)[],
  enabled?: boolean,
) {
  return _useGet<T1>(
    path,
    keys,
    enabled,
    true,
    `${process.env.REACT_APP_EXHUT_BE_URL}/exhut-be`,
  );
}
