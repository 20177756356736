import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useRegisterMutation, useRegisterSSOMutation } from "../api/api";
import ErrorPanel from "../components/error.panel.component";
import StatusMessageComponent from "../components/status-message.component";
import { useLayoutContext } from "../providers/layout.provider";
import { IStatusMsg } from "../types/types";
import { EXHUT_BIZ_LOCAL_STORAGE_TOKEN, ROUTES } from "../utils/constants";
import { downloadAndLoadGSIScript } from "../utils/lib.helper";
import { getRecaptchaKey, isRecaptchaEnabled } from "../utils/functions";
import ReCAPTCHA from "react-google-recaptcha";
import TermsConditionsComponent from "../components/other/TermsConditionsComponent";

export default function RegisterPage() {
  const { mutateAsync, isPending: isLoading } = useRegisterMutation();
  const { mutateAsync: registerWithSSO } = useRegisterSSOMutation();
  const { isMobile } = useLayoutContext();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg>();
  const [recaptchaVerified, setRecaptchaVerified] =
    useState(!isRecaptchaEnabled);

  const navigate = useNavigate();

  useEffect(() => {
    downloadAndLoadGSIScript()
      .then(() => {
        (window as any).google.accounts.id.initialize({
          client_id: process.env.REACT_APP_EXHUT_GOOGLE_CLIENT_ID,
          callback: (response: any) => {
            registerWithSSO({ token: response.credential })
              .then((resp) => {
                localStorage.setItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN, resp.sign);
                navigate(ROUTES.HOME);
              })
              .catch((e) => {
                let msg = "Whoops! Something went wrong. Please try again.";
                if (e.response?.data?.status === 409) {
                  msg = e.response?.data?.message;
                }
                setStatusMsg({ type: "error", text: msg });
              });
          },
        });
        (window as any).google.accounts.id.renderButton(
          document.querySelector(".sso-signin"),
          { theme: "dark", size: "medium", text: "signup_with" }, // customization attributes
        );
      })
      .catch((err) => console.error(err));
  }, [registerWithSSO, navigate]);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d@$#!%*?&]{8,}$/,
          "Must contain atleast 1 character and 1 number with minimum of 8 chars.",
        )
        .min(8, "Minimum of 8 characters")
        .required("Password is required"),
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
          "Please enter valid email address",
        )
        .required("Please enter email."),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      mutateAsync(values)
        .then(() => {
          navigate(ROUTES.LOGIN + "?register=success");
        })
        .catch((e) => {
          let msg = "Whoops! Something went wrong. Please try again.";
          if (
            e.response?.data?.message ===
            "An account already exists with this email."
          ) {
            msg = e.response?.data?.message;
          }
          setStatusMsg({ type: "error", text: msg });
        });
    },
  });

  const setLoginReady = (e: any) => {
    setRecaptchaVerified(true);
  };

  return (
    <div className={`splitright ${isMobile ? "mobile-splitright my-20" : ""}`}>
      <div className={`${isMobile ? "divColumn" : "divFlex my-40"}`}>
        <div
          style={{
            borderRight: "1px solid black",
            flex: 1,
          }}
        >
          <div className="divCenterAlign">
            <h2 style={{ color: "#2c82ff" }}>Register</h2>
          </div>

          <div style={{ minHeight: 40 }} className="mx-20">
            <StatusMessageComponent
              onClose={() => setStatusMsg(undefined)}
              message={statusMsg}
            />
            {isLoading && <div className="divCenterAlign"></div>}
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="divAlignItemsOnly divColumn px-20 pb-40"
          >
            <div style={styles.formColumn as React.CSSProperties}>
              <strong className="font-larger">Email</strong>

              <input
                id="email"
                name="email"
                type="text"
                className="bordered mt-10"
                style={{ width: 290 }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
            </div>

            <div style={styles.formRowMin}>
              <ErrorPanel message={formik.errors.email} />
            </div>

            <div style={styles.formColumn as React.CSSProperties}>
              <strong className="font-larger">Password</strong>

              <input
                id="password"
                name="password"
                type="password"
                className="bordered mt-10"
                style={{ width: 290 }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
            </div>

            <div style={{ maxWidth: 300, fontSize: "small" }}>
              <ErrorPanel message={formik.errors.password} />
            </div>

            {isRecaptchaEnabled && (
              <ReCAPTCHA sitekey={getRecaptchaKey()} onChange={setLoginReady} />
            )}

            <div className="divColumn">
              <div className="divColumn my-20">
                <input
                  type="submit"
                  disabled={isLoading || !recaptchaVerified}
                  value={"Sign Up"}
                  className="bigButtonGreen"
                />
              </div>

              <div
                className="sso-signin mb-20"
                style={{ width: "200px" }}
              ></div>

              <div className="my-20">
                <Link to="/login">Already registered?</Link>
              </div>
            </div>
          </form>
        </div>
      </div>

      <hr />

      <TermsConditionsComponent />
    </div>
  );
}

const styles = {
  formRowMin: {
    marginTop: 0,
    display: "flex",
    fontSize: "small",
  },
  formRow: {
    marginTop: 30,
    display: "flex",
  },
  formColumn: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
  },
};
