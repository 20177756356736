import { DatePicker, Flex, Modal, Select } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreatePayrollSchedule } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";

export default function AddPayrollScheduleModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();

  const { mutateAsync, isPending } = useCreatePayrollSchedule(id);

  const formik = useFormik({
    initialValues: {
      schedule: "",
      startDate: "",
    },
    validationSchema: Yup.object({
      schedule: Yup.string().required("Please select schedule"),
      startDate: Yup.string().required("Please select date"),
    }),
    onSubmit: async (values) => {
      mutateAsync({ ...values })
        .then(() => {
          onCancel();
        })
        .catch(console.error);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Payroll Schedule"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
        okButtonProps={{ loading: isPending }}
      >
        <Flex gap={30} vertical>
          <div>
            <Select
              style={{ minWidth: 200 }}
              placeholder="Select a schedule"
              optionFilterProp="label"
              onChange={(value, option) =>
                formik.setFieldValue("schedule", value)
              }
              options={[
                {
                  value: "weekly",
                  label: "Weekly",
                },
                {
                  value: "biweekly",
                  label: "Bi-Weekly",
                },
                {
                  value: "monthly",
                  label: "Monthly",
                },
                {
                  value: "fifteenth",
                  label: "Fifteenth",
                },
              ]}
            />
            <ErrorPanel message={formik.errors.schedule} />
          </div>

          <div>
            <DatePicker
              disabledDate={(current) =>
                current && current < dayjs().endOf("day")
              }
              onChange={(value) => formik.setFieldValue("startDate", value)}
            />

            <ErrorPanel message={formik.errors.startDate} />
          </div>
        </Flex>
      </Modal>
    </form>
  );
}
