import { Button, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDeleteOrgExpense } from "../../../api/api.delete";
import { useGetOrganizationExpenses } from "../../../api/api.get";
import { IOrganizationExpense } from "../../../types/types";
import OrganizationDate from "../organization-date";
import ViewExpenseModal from "./view-expense-modal";

const { Title } = Typography;

export default function InvoicesHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());

  const { data: expenses, refetch } = useGetOrganizationExpenses(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );
  const [viewableExpenseObj, setViewableExpenseObj] =
    useState<IOrganizationExpense>();

  const [deletableExpenseId, setDeletableExpenseId] = useState<string>();

  const { mutateAsync: deleteAsync } = useDeleteOrgExpense(
    id!!,
    deletableExpenseId!!,
  );
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const handleView = (row: any) => {
    setViewableExpenseObj(row);
  };

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString));
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
  }, [date]);

  const handleDelete = (row: any) => {
    setDeletableExpenseId(row.id);
    deleteAsync(null)
      .then(() => refetch())
      .catch(console.error);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date",
      dataIndex: "expenseDate",
      key: "expenseDate",
      render: (value: string) => (
        <span>{moment(value).format("DD MMM YYYY")}</span>
      ),
    },
    {
      title: "",
      key: "operationView",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => handleView(row)}>
          Edit
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Button
          danger
          type="text"
          color="red"
          onClick={() => handleDelete(row)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="body divColumn mx-40 my-40 flex-1"
    >
      <ViewExpenseModal
        open={!!viewableExpenseObj}
        onCancel={() => setViewableExpenseObj(undefined)}
        expense={viewableExpenseObj}
      />
      <div className="divCenterAlign">
        <OrganizationDate onChange={handleDateChange} />
      </div>
      <div className="divSpread divAlignItemsOnly mt-40">
        <Title level={3}>Invoices</Title>

        <Link to="create">Create</Link>
      </div>
      <Table dataSource={expenses} columns={columns} />
    </div>
  );
}
