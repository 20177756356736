import { Button, notification, Space } from "antd";
import { useEffect } from "react";

interface IProps {
  title?: string;
  subtitle?: string;
}

export function useNotifications(props?: IProps) {
  const [api, contextHolder] = notification.useNotification();

  const notifySuccess = () => {
    api.success({
      message: props?.title ?? "Success",
      description: props?.subtitle,
    });
  };

  const notifyError = () => {
    api.error({
      message: "Whoops!",
      description: "Something went wrong. Please try again.",
    });
  };

  return { notifySuccess, notification: contextHolder, notifyError };
}
