import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useLocation } from "react-router-dom";
import * as animationData from "../animations/business-home-lottie.json";
import { useGetAllOrganizations } from "../api/api.get";
import OrganizationTile from "../components/tile.component";
import { capitalize } from "../utils/functions";
import FirstTimePage from "./home/first-time.page";

export default function HomePage() {
  const { state } = useLocation();

  const { animate } = state || {};

  const { data: profile } = useGetAllOrganizations();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (animate) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [animate]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={`mb-160 flex-1`} id="home-body">
      {isLoading && (
        <Lottie options={defaultOptions} height={400} width={400} />
      )}

      {!isLoading && (
        <>
          {profile && profile.length === 0 && <FirstTimePage />}

          {profile && profile.length > 0 && (
            <div className="divColumn" style={{ minHeight: 600 }}>
              <div className={"divColumn divCenterAlign"}>
                {profile.map((p) => {
                  return (
                    <OrganizationTile
                      title={capitalize(p.org_name)}
                      id={p.org_id.toString()}
                      admin={p.type === "admin"}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
