import { Button, Card, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDeleteOrgExpense } from "../../../api/api.delete";
import { useGetTimeSheets } from "../../../api/api.get";
import OrganizationDate from "../organization-date";
import OrganizationDetailsSection, {
  OrganizationName,
} from "../organization-details.section";
import AddTimeSheetModal from "./add-timesheet-modal";
import Box from "../../../components/box.component";

const { Title } = Typography;

export default function TimeSheetsHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());

  const { data: timesheets, refetch } = useGetTimeSheets(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );

  const [deletableExpenseId, setDeletableExpenseId] = useState<string>();

  const { mutateAsync: deleteAsync } = useDeleteOrgExpense(
    id!!,
    deletableExpenseId!!,
  );
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString, "MMM YYYY"));
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
  }, [date]);

  const handleDelete = (row: any) => {
    setDeletableExpenseId(row.id);
    deleteAsync(null)
      .then(() => refetch())
      .catch(console.error);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "timeSheetDate",
      key: "timeSheetDate",
      render: (value: string) => (
        <span>{moment(value).format("DD MMM YYYY")}</span>
      ),
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Button
          danger
          type="text"
          color="red"
          onClick={() => handleDelete(row)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div style={{ marginBottom: 100 }} className="w-100 divColumn mx-40">
      <AddTimeSheetModal open={isAdd} onCancel={() => setIsAdd(false)} />

      <div className="divSpread my-20">
        <Link to={`/organizations/${id}`}>Back</Link>
        <OrganizationName />
      </div>

      <Box>
        <div className="divSpread divAlignItemsOnly mt-40">
          <Title level={3}>Timesheets</Title>
          <OrganizationDate onChange={handleDateChange} />
          <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
            Add
          </Button>
        </div>
        <Table dataSource={timesheets} columns={columns} />
      </Box>
    </div>
  );
}
