import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { IProfile } from "../types/types";
import { EXHUT_BIZ_LOCAL_STORAGE_TOKEN } from "../utils/constants";
import {
  ICreateOrgExpensePayload,
  ICreateOrgInvestmentPayload,
  ICreateOrgPayload,
  ICreateOrgSalePayload,
  ICreateProductPayload,
} from "./api";

export function useUpdateSale(id?: string, sid?: string) {
  return _usePatch<any, any, ICreateOrgSalePayload>({
    path: `organizations/${id}/sales/${sid}`,
    keys: ["organizations", id!!, "sales", sid!!],
    resetKeys: [],
  });
}

export function useUpdateInvestment(id?: string, investmentId?: string) {
  return _usePatch<any, any, ICreateOrgInvestmentPayload>({
    path: `organizations/${id}/investments/${investmentId}`,
    keys: ["organizations", id!!, "investments", investmentId!!],
    resetKeys: [],
  });
}

export function useUpdateExpense(id?: string, eid?: string) {
  return _usePatch<any, any, ICreateOrgExpensePayload>({
    path: `organizations/${id}/expenses/${eid}`,
    keys: ["organizations", id!!, "expenses", eid!!],
    resetKeys: [],
  });
}

type IUpdateOrgPayload = Partial<ICreateOrgPayload>;

export function useUpdateOrganization(id?: string) {
  return _usePatch<any, any, IUpdateOrgPayload>({
    path: `organizations/${id}`,
    keys: ["organizations", id!!],
    resetKeys: [],
  });
}

export type IUpdateProfile = Omit<IProfile, "email" | "entitlement">;

export function useUpdateProfile() {
  return _usePut<any, any, IUpdateProfile>({
    path: `users/profile`,
    keys: ["profile"],
  });
}

interface IUpdateSettings {
  entitlement?: number;
  status?: boolean;
}
interface IUpdateSettingsPayload {
  username: string;
  settings: IUpdateSettings;
}

export function useUpdateSettings() {
  return _usePut<any, any, IUpdateSettingsPayload>({
    path: `admin/users/settings`,
    keys: ["profile"],
  });
}

function _usePut<T1, T2, T3>({
  path,
  keys,
  resetKeys,
  host,
}: {
  path: string;
  keys?: string[];
  resetKeys?: string[];
  host?: string;
}) {
  const _host = host ?? process.env.REACT_APP_BE_URL;
  const _url = `${_host}/${path}`;

  let headers: any = {
    "Content-Type": "application/json",
  };
  headers["Authorization"] = localStorage.getItem(
    EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
  );

  const queryClient = useQueryClient();

  return useMutation<T1, T2, T3>({
    mutationFn: async (req) => {
      return axios
        .put(_url, req, {
          headers,
        })
        .then((resp) => resp.data);
    },
    mutationKey: keys,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: resetKeys }),
  });
}

interface IUpdateEmployee {
  employmentType: string;
  salary: number;
}

export function useUpdateEmployee(orgId?: string, empId?: string) {
  return _usePatch<any, any, IUpdateEmployee>({
    path: `organizations/${orgId}/employees/${empId}`,
    keys: ["profile"],
  });
}

export type IUpdateProductPayload = Partial<ICreateProductPayload>;

export function useUpdateProduct(orgId?: string, productId?: string) {
  return _usePatch<any, any, IUpdateProductPayload>({
    path: `organizations/${orgId}/products/${productId}`,
    keys: ["profile"],
    host: process.env.REACT_APP_PRODUCTS_URL,
  });
}

function _usePatch<T1, T2, T3>({
  path,
  keys,
  resetKeys,
  host,
}: {
  host?: string;
  path: string;
  keys?: string[];
  resetKeys?: string[];
}) {
  const _host = host ?? process.env.REACT_APP_BE_URL;
  const _url = `${_host}/${path}`;

  let headers: any = {
    "Content-Type": "application/json",
  };
  headers["Authorization"] = localStorage.getItem(
    EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
  );

  const queryClient = useQueryClient();

  return useMutation<T1, T2, T3>({
    mutationFn: async (req) => {
      return axios
        .patch(_url, req, {
          headers,
        })
        .then((resp) => resp.data);
    },
    mutationKey: keys,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: resetKeys }),
  });
}
