import { Button, Table, Typography } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetOrganizationById,
  useGetOrganizationEmployees,
  useGetOrganizationEmployeesSalary,
  useGetOrganizationEmployeesSalaryTotal,
} from "../../../api/api.get";
import Box from "../../../components/box.component";
import { getCurrencySymbol } from "../../../utils/functions";
import OrganizationDate from "../organization-date";
import AddExpenseModal from "./add-employee-modal";
import ViewExpenseModal from "./view-employee-modal";

const { Title } = Typography;

export default function OrganizationEmployeesHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState<Moment>(moment());

  const { data: orgData } = useGetOrganizationById(id);

  const currency = useMemo(() => {
    return getCurrencySymbol(orgData?.country);
  }, [orgData]);

  const { data: employees } = useGetOrganizationEmployees(id);

  const { data: empSalaryTotal, refetch: refetchSalaryTotal } =
    useGetOrganizationEmployeesSalaryTotal(
      id,
      date.startOf("M").format("YYYY-MM-DD"),
      date.endOf("M").format("YYYY-MM-DD"),
    );

  const { data: empSalary, refetch: refetchSalary } =
    useGetOrganizationEmployeesSalary(
      id,
      date.startOf("M").format("YYYY-MM-DD"),
      date.endOf("M").format("YYYY-MM-DD"),
    );

  useEffect(() => {
    if (date) {
      refetchSalary();
      refetchSalaryTotal();
    }
  }, [date]);

  const [isAdd, setIsAdd] = useState<boolean>(false);

  const [viewEmployeeId, setViewEmployeeId] = useState<string>();

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Salaried/Contractor",
      dataIndex: "employmentType",
      key: "employmentType",
      render: (value: string) => (
        <span>
          {value ? (value === "salaried" ? "Salaried" : "Contractor") : ""}
        </span>
      ),
    },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
      render: (value: string) => <span>{value}</span>,
    },
    {
      title: "Start Date",
      dataIndex: "employmentStartDate",
      key: "employmentStartDate",
      render: (value: string) => (
        <span>{value ? moment(value).format("DD MMM YYYY") : ""}</span>
      ),
    },
    {
      title: "End Date",
      dataIndex: "employmentEndDate",
      key: "employmentEndDate",
      render: (value: string) => (
        <span>{value ? moment(value).format("DD MMM YYYY") : ""}</span>
      ),
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => setViewEmployeeId(row.id)}>
          View
        </Button>
      ),
    },
  ];

  const salaryColumns = [
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width: 100,
    },
    {
      title: "Total Hours",
      key: "hours",
      dataIndex: "hours",
      width: 100,
    },
    {
      title: "Total Salary",
      key: "salary",
      dataIndex: "salary",
      width: 100,
    },
  ];

  return (
    <div style={{ marginBottom: 100 }} className="divColumn mx-40 my-20 flex-1">
      <AddExpenseModal open={isAdd} onCancel={() => setIsAdd(false)} />
      <ViewExpenseModal
        open={!!viewEmployeeId}
        empId={viewEmployeeId!!}
        onCancel={() => setViewEmployeeId(undefined)}
      />

      <div className="divSpread divAlignItemsOnly mt-40">
        <Title level={3}>Employees</Title>

        <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
          Add
        </Button>
      </div>
      <Table
        dataSource={employees}
        pagination={{ pageSize: 100 }}
        columns={columns}
      />

      <div
        className="mt-80"
        style={{
          padding: "40px 30px",
          border: "1px solid darkgrey",
          borderRadius: 20,
        }}
      >
        <div className="divCenterAlign">
          <OrganizationDate
            onChange={(date: string) => setDate(moment(date, "MMM YYYY"))}
          />
        </div>

        <div className="divSpread divAlignItemsOnly mt-40">
          <Title level={3}>Estimated Salary</Title>

          <Title prefix={currency} level={3}>
            {empSalaryTotal?.total.toFixed(2)}
          </Title>
        </div>
        <Table dataSource={empSalary} columns={salaryColumns} />
      </div>
    </div>
  );
}

interface ITileProps {
  title: string;
  id: string;
  admin: boolean;
  path?: string;
}
