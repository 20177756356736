import {
  LikeOutlined,
  PlusOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Flex, Row, Statistic, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useGetOrganizationById,
  useGetOrganizationEmployeesSalaryTotal,
  useGetOrganizationExpensesTotal,
  useGetOrganizationInvestmentsTotal,
  useGetOrganizationSalesTotal,
  useGetReimbursements,
  useGetRewards,
} from "../../../api/api.get";
import { useLayoutContext } from "../../../providers/layout.provider";
import { getCurrencySymbol } from "../../../utils/functions";
import AddRewardModal from "../rewards/add-reward.modal";
import OrganizationDate from "../organization-date";
import SalesChartComponent from "./sales.chart.component";
import ExpensesChartComponent from "./expenses.chart.component";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import AddTransactionModal from "../add-transactions.modal";

export default function BusinessOwnerSection() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());
  const { isMobile } = useLayoutContext();

  const { data: orgData } = useGetOrganizationById(id);

  const [isAdd, setIsAdd] = useState<boolean>(false);

  const currency = useMemo(
    () => getCurrencySymbol(orgData?.country),
    [orgData],
  );

  const [addRewardMode, setAddRewardMode] = useState<boolean>(false);

  const { data: expensesTotal, refetch } = useGetOrganizationExpensesTotal(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );

  const { data: reimbursements, refetch: refetchReimbursements } =
    useGetReimbursements(
      id,
      date.startOf("month").format("YYYY-MM-DD"),
      date.endOf("month").format("YYYY-MM-DD"),
    );

  const { data: salaryTotal, refetch: refetchSalaryTotal } =
    useGetOrganizationEmployeesSalaryTotal(
      id,
      date.startOf("month").format("YYYY-MM-DD"),
      date.endOf("month").format("YYYY-MM-DD"),
    );

  const { data: rewards, refetch: refetchRewards } = useGetRewards(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );

  // investments total are calculated for investments that were created from that month.
  // For example if investment is created Sept 20th, then we show in Sept, Oct and forward but not to Aug
  const { data: investmentsTotal, refetch: refetchInvestments } =
    useGetOrganizationInvestmentsTotal(
      id,
      date.endOf("month").format("YYYY-MM-DD"),
    );

  const { data: salesTotal, refetch: refetchSales } =
    useGetOrganizationSalesTotal(
      id,
      date.startOf("month").format("YYYY-MM-DD"),
      date.endOf("month").format("YYYY-MM-DD"),
    );

  useEffect(() => {
    if (date) {
      refetch();
      refetchSales();
      refetchInvestments();
      refetchSalaryTotal();
      refetchReimbursements();
      refetchRewards();
    }
  }, [date]);

  const profit = useMemo(() => {
    return (
      (salesTotal?.total ?? 0) +
      (investmentsTotal?.total ?? 0) -
      ((salaryTotal?.total ?? 0) + (expensesTotal?.total ?? 0))
    ).toFixed(2);
  }, [salesTotal, expensesTotal, investmentsTotal]);

  return (
    <div className="mb-80 divCenterAlign divColumn">
      <AddRewardModal
        open={addRewardMode}
        onCancel={() => setAddRewardMode(false)}
        currency={currency}
        countryCode={orgData?.country}
      />

      <AddTransactionModal open={isAdd} onCancel={() => setIsAdd(false)} />

      <div className="w-100 divSpread">
        <OrganizationDate onChange={(d) => setDate(moment(d))} />

        <Button
          onClick={() => setIsAdd(!isAdd)}
          icon={<PlusOutlined />}
          type="primary"
        ></Button>
      </div>

      <Row gutter={[20, 20]} className="mt-40">
        <div className="card mb-60 p-40">
          <h4>Net Profit</h4>
          <div>
            <h1 id="net-proceeds-value">
              {currency}
              {profit ?? 0}
            </h1>
          </div>
        </div>

        <Card
          value={salesTotal?.total}
          currency={currency}
          title={"Sales"}
          link={`/organizations/${id}/sales`}
        />

        <Card
          value={expensesTotal?.total}
          currency={currency}
          title={"Expenses"}
          link={`/organizations/${id}/expenses`}
        />

        <Card
          value={investmentsTotal?.total}
          currency={currency}
          title={"Investments"}
          link={`/organizations/${id}/investments`}
        />

        <Card
          value={salaryTotal?.total}
          currency={currency}
          title={"Employees Salary"}
          link={`/organizations/${id}/employees`}
        />

        <Card
          value={rewards?.length}
          title={"Rewards"}
          type="none"
          link={`/organizations/${id}/rewards`}
        />

        <Card
          value={reimbursements?.length}
          type="none"
          title={"Reimbursements"}
          link={`/organizations/${id}/reimbursements`}
        />
      </Row>

      <Divider style={{ marginTop: 120, backgroundColor: "bisque" }} />

      <div
        id="sales-charts-container"
        className="my-40"
        style={{ marginTop: 0, height: 400, width: "100%" }}
      >
        <div className="my-40">
          <Typography.Title level={3}>Sales</Typography.Title>
        </div>
        <SalesChartComponent />
      </div>

      <Divider style={{ marginTop: 200, backgroundColor: "bisque" }} />

      <div
        id="expenses-charts-container"
        className="my-0"
        style={{ marginTop: 0, height: 400, width: "100%" }}
      >
        <div className="my-40">
          <Typography.Title level={3}>Expenses</Typography.Title>
        </div>
        <ExpensesChartComponent />
      </div>
    </div>
  );
}

interface IProps {
  value?: number;
  currency?: string;
  id?: string;
  title: string;
  link?: string;
  type?: "currency" | "none";
}

function Card({ value, currency, id, title, link, type = "currency" }: IProps) {
  return (
    <div className="card">
      <span className="infoMsg">{title}</span>
      <div className="mt-20">
        <span style={{ fontSize: "xx-large" }} id={id}>
          {currency}{" "}
          <>
            {type === "currency" && (value?.toFixed(2) ?? 0.0)}
            {type === "none" && (value ?? 0)}
          </>
        </span>
      </div>
      {link && (
        <div className="mt-20">
          <Link to={link}>View</Link>
        </div>
      )}
    </div>
  );
}
