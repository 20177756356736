import {
  Alert,
  Avatar,
  Button,
  Divider,
  Input,
  Layout,
  Modal,
  Select,
} from "antd";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateOrganization } from "../../api/api";
import ErrorPanel from "../../components/error.panel.component";
import { FormRowCol } from "../../components/forms/form.row.component";
import { ROUTES } from "../../utils/constants";
import { useGetOrganizationById } from "../../api/api.get";
import { useUpdateOrganization } from "../../api/api.update";

import { Typography, message } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useDeleteOrg, useDeleteOrgInvestment } from "../../api/api.delete";
import { capitalize } from "../../utils/functions";
import { useNotifications } from "../../components/notifications/notification";

const { Title } = Typography;

export default function OrganizationSettings() {
  const navigate = useNavigate();

  const { id } = useParams();
  const { mutateAsync } = useUpdateOrganization(id);

  const { mutateAsync: deleteOrgAsync } = useDeleteOrg(id);
  const [errorMsg, setErrorMsg] = useState<string>();

  const { data: orgData } = useGetOrganizationById(id);
  const [orgDeleteConfirmText, setOrgDeleteConfirmText] = useState<string>();

  const [deletableOrgId, setDeletableOrgId] = useState<string>();
  const { notifySuccess, notification } = useNotifications({
    title: "Success",
    subtitle: "Organization successfully updated",
  });

  const handleOrgDelete = () => {
    setDeletableOrgId(id);
    deleteOrgAsync(null)
      .then(() => {
        navigate(ROUTES.HOME);
      })
      .catch(() => {
        message.error("Sorry! Something went wrong. Please try again.");
      });
  };

  const formik = useFormik({
    initialValues: {
      website: orgData?.website ?? "",
      street1: orgData?.street1 ?? "",
      street2: "",
      city: orgData?.city ?? "",
      state: orgData?.state ?? "",
      country: orgData?.country ?? "",
      zipCode: orgData?.zipCode ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      website: Yup.string(),
      street1: Yup.string().required("Please enter name"),
      city: Yup.string().required("Please enter city"),
      state: Yup.string().required("Please enter state"),
      country: Yup.string().required("Please select a country"),
      zipCode: Yup.string()
        .matches(/^[0-9]+$/, "Zip code or pin code must be digits")
        .required("Please enter zip.")
        .min(5, "Invalid zipcode")
        .max(7, "Invalid zipcode"),
    }),
    onSubmit: async (values) => {
      setErrorMsg(undefined);
      mutateAsync({ ...values, zipCode: +values.zipCode })
        .then(() => {
          notifySuccess();
        })
        .catch((err) => {
          if (err.response.data.message === "Address is invalid") {
            setErrorMsg(
              "Unable to verify address. Please double check if you have entered full address including house or unit number.",
            );
          } else {
            setErrorMsg("Whoops! Unable to submit data. Please try again.");
          }
        });
    },
  });

  return (
    <div id="org-settings-container" className="p-40 flex-1">
      {notification}
      <div className={`divCenterAlign divColumn p-32`}>
        <Modal
          title="Delete Organization"
          open={!!deletableOrgId}
          onOk={handleOrgDelete}
          onCancel={() => setDeletableOrgId(undefined)}
          okText="Confirm"
          cancelText="Cancel"
          okButtonProps={{ disabled: orgDeleteConfirmText !== "confirm" }}
        >
          <p>Are you sure?</p>
          <p>
            This is a permanent action and you will not be able to recover any
            data associated with this organizaton.
          </p>

          <p>
            <Input
              id="confirm"
              onChange={(e) => setOrgDeleteConfirmText(e.target.value)}
              placeholder="Type 'confirm'"
            />
          </p>
        </Modal>

        <Content
          className="p-20"
          style={{ minWidth: "40%", boxShadow: "3px 1px 5px 5px #e6ddd6" }}
        >
          <div className="w-100">
            <div className="mb-80">
              <div className="title mb-10">{capitalize(orgData?.name)}</div>
            </div>

            <div className="divCenterAlign my-0">
              <Avatar
                style={{ width: 56, height: 56 }}
                src={"/assets/icons/cityscape.png"}
              />
            </div>

            <div className="mt-80 mb-60 divCenterAlign">
              <form onSubmit={formik.handleSubmit}>
                <FormRowCol label="Website">
                  <div className="divColumn">
                    <Input {...formik.getFieldProps("website")} />
                    <ErrorPanel message={formik.errors.website} />
                  </div>
                </FormRowCol>

                <FormRowCol label="Street 1">
                  <Input {...formik.getFieldProps("street1")} />
                  <ErrorPanel message={formik.errors.street1} />
                </FormRowCol>

                <FormRowCol label="Street 2">
                  <Input {...formik.getFieldProps("street2")} />
                  <ErrorPanel message={formik.errors.street2} />
                </FormRowCol>

                <FormRowCol label="City">
                  <Input {...formik.getFieldProps("city")} />
                  <ErrorPanel message={formik.errors.city} />
                </FormRowCol>

                <FormRowCol label="State">
                  <Input {...formik.getFieldProps("state")} />
                  <ErrorPanel message={formik.errors.state} />
                </FormRowCol>

                <FormRowCol label="Country">
                  <Select
                    style={{ width: "auto" }}
                    options={[
                      { value: "CA", label: "Canada" },
                      { value: "IND", label: "India" },
                      { value: "SG", label: "Singapore" },

                      { value: "UK", label: "United Kingdom" },
                      { value: "USA", label: "USA" },
                    ]}
                    {...formik.getFieldProps("country")}
                    onChange={(value) => formik.setFieldValue("country", value)}
                  />
                  <ErrorPanel message={formik.errors.country} />
                </FormRowCol>

                <FormRowCol label="Zipcode">
                  <Input {...formik.getFieldProps("zipCode")} />
                  <ErrorPanel message={formik.errors.zipCode} />
                </FormRowCol>

                {errorMsg && (
                  <Alert
                    type="error"
                    message={errorMsg}
                    style={{ width: 300 }}
                  />
                )}

                <FormRowCol label="">
                  <Button type="primary" onClick={formik.submitForm}>
                    Update
                  </Button>
                </FormRowCol>
              </form>
            </div>

            <Divider />

            <div className="mb-40 p-20" style={{ backgroundColor: "#ffa1a1" }}>
              <Title level={4}>Danger Zone</Title>
              <Button
                type="primary"
                danger
                onClick={() => setDeletableOrgId(id)}
              >
                Delete
              </Button>
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
}
