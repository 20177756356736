import { DeleteFilled, LoadingOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  AutoCompleteProps,
  Flex,
  Input,
  List,
  Select,
  Spin,
} from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import * as Yup from "yup";
import { useCreateOrgExpense } from "../../../api/api";
import { useSearchProducts } from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { IProduct } from "../../../types/types";
import { reimbursementCategories } from "../../../utils/reimbursements";

export default function AddInvoice() {
  const { id } = useParams();
  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Expense created successfully.",
  });
  const [servicesSearchText, setServicesSearchText] = useState<string>("");
  const [debouncedSearchText] = useDebounce(servicesSearchText, 500);
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([]);
  const [items, setItems] = useState<Partial<IProduct>[]>([]);

  const { data, isLoading, error } = useSearchProducts(id, debouncedSearchText);

  const { mutateAsync, isPending } = useCreateOrgExpense(id);

  const formik = useFormik({
    initialValues: {
      category: "",
      amount: "",
      tax: "",
      customerPhone: "",
      customerEmail: "",
    },
    validationSchema: Yup.object({
      amount: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid salary")
        .required("Please enter amount"),
      tax: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid salary")
        .required("Please enter amount"),
      category: Yup.string(),
      customerPhone: Yup.string(),
      customerEmail: Yup.string(),
    }),
    onSubmit: async (values) => {},
  });

  useEffect(() => {
    if (data) {
      setOptions(
        data.map((product: IProduct) => {
          return {
            value: product.name,
            name: product.name,
            id: product._id,
            price: product.price,
          };
        }),
      );
    }
  }, [data]);

  const handleAddItems = useCallback(
    (option: DefaultOptionType) => {
      if (options) {
        const temp = [...items];
        temp.push({
          _id: option._id,
          name: option.name ?? "",
          price: option.price,
        });
        setItems(temp);
      }
    },
    [options],
  );

  const handleDeleteItems = (_item: Partial<IProduct>) => {
    const temp = [...items];
    const index = temp.findIndex((item) => item._id === _item._id);
    if (index >= 0) {
      temp.splice(index, 1);
    }

    setItems(temp);
  };

  const total = useMemo(() => {
    const _tax = +formik.values.tax / 100;
    if (formik.values.amount) {
      return +formik.values.amount + _tax * +formik.values.amount;
    }
    if (items.length) {
      return (
        _tax + items.reduce((acc, item) => acc + (item.price?.value ?? 0), 0)
      );
    }
    return 0;
  }, [items, formik.values.amount, formik.values.tax]);

  return (
    <div
      style={{ marginBottom: 100 }}
      className="body divColumn mx-40 my-40 flex-1"
    >
      <h1>Create Invoice</h1>

      <div className="mb-80">
        <Link to={`/organizations/${id}/invoices`}>Back</Link>
      </div>

      <Flex gap={30} vertical>
        <div>
          <AutoComplete
            options={options}
            style={{ width: 300 }}
            onSelect={(_, option) => handleAddItems(option)}
            onSearch={(text) => setServicesSearchText(text)}
            placeholder="Search services or products..."
          />
        </div>

        <List
          itemLayout="horizontal"
          dataSource={items}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <DeleteFilled
                  onClick={() => handleDeleteItems(item)}
                  style={{ color: "var(--exhut-light-red)" }}
                >
                  delete
                </DeleteFilled>,
              ]}
            >
              <List.Item.Meta
                title={item.name}
                description={"$" + item.price?.value}
              />
            </List.Item>
          )}
        />
      </Flex>

      <form onSubmit={formik.handleSubmit}>
        {notification}
        <Flex gap={30} vertical>
          <div className="mt-40">
            <Input
              prefix={"Total"}
              placeholder="Total Amount"
              {...formik.getFieldProps("amount")}
            />
            <ErrorPanel message={formik.errors.amount} />
          </div>

          <div>
            <Input
              prefix={"%"}
              placeholder="Taxes"
              {...formik.getFieldProps("tax")}
            />
            <ErrorPanel message={formik.errors.tax} />
          </div>

          <div>
            <Select
              showSearch
              style={{ minWidth: 200 }}
              placeholder="Select a category"
              optionFilterProp="label"
              onChange={(value, option) =>
                formik.setFieldValue("category", (option as any).label)
              }
              options={reimbursementCategories}
            />

            <ErrorPanel message={formik.errors.category} />
          </div>

          <div>
            <Input placeholder="Notes" {...formik.getFieldProps("notes")} />
          </div>

          <div>
            <span>Total</span>
            <h1> ${total.toFixed(2)}</h1>
            {isLoading && (
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            )}
          </div>
        </Flex>
      </form>
    </div>
  );
}
