import { DatePicker, Flex, Input, Modal } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateOrgSale } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";

export default function AddSalesModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { currency } = useCurrency();

  const { notifySuccess, notification } = useNotifications({
    title: "Success",
    subtitle: "Sale created successfully.",
  });

  const { mutateAsync, isPending } = useCreateOrgSale(id);

  const formik = useFormik({
    initialValues: {
      category: "",
      amount: "",
      saleDate: "",
      notes: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required(
        "Please enter a description. For ex: Sold 2 tables...",
      ),
      saleDate: Yup.string().required("Please select date"),
      amount: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid salary")
        .required("Please enter amount"),
    }),
    onSubmit: async (values) => {
      mutateAsync({ ...values, amount: +values.amount })
        .then(() => {
          formik.resetForm();
          notifySuccess();
          onCancel();
        })
        .catch(console.error);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {notification}
      <Modal
        title="Add Order"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
        okButtonProps={{ loading: isPending }}
      >
        <Flex gap={30} vertical style={{ padding: 20 }}>
          <div>
            <Input
              placeholder="Description - For ex: Sold 2 desks.."
              {...formik.getFieldProps("category")}
            />
            <ErrorPanel message={formik.errors.category} />
          </div>

          <div>
            <Input
              prefix={currency}
              placeholder="Amount"
              {...formik.getFieldProps("amount")}
            />
            <ErrorPanel message={formik.errors.amount} />
          </div>

          <div>
            <DatePicker
              onChange={(value) => formik.setFieldValue("saleDate", value)}
            />

            <ErrorPanel message={formik.errors.saleDate} />
          </div>

          <div>
            <Input.TextArea
              placeholder="Notes"
              {...formik.getFieldProps("notes")}
            />
          </div>
        </Flex>
      </Modal>
    </form>
  );
}
