import { IdleTimerProvider } from "react-idle-timer";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import HomePage from "./pages/home.page";
import OrganizationEmployeesHomePage from "./pages/organizations/employees/employees-home";
import OrganizationExpensesHomePage from "./pages/organizations/expenses/expenses-home";
import OrganizationInvestmentsHomePage from "./pages/organizations/investments/investments-home";
import OrganizationHomePage from "./pages/organizations/organization-home";
import OrganizationSalesHomePage from "./pages/organizations/sales/sales-home";
import TimeSheetsHomePage from "./pages/organizations/timesheets/timesheet-home";
import OrganizationSettings from "./pages/organizations/settings";
import RewardsHomePage from "./pages/organizations/rewards/reward-home";
import ProductsHomePage from "./pages/organizations/products/products-home";
import InvoicesHomePage from "./pages/organizations/invoices/invoices.home";
import AddInvoice from "./pages/organizations/invoices/add-invoice";
import ReimbursementsHomePage from "./pages/organizations/reimbursements/reimbursements-home";
import {
  RequireEmployeeAccess,
  RequireOrgAdminAccess,
} from "./higher-order/require-access";
import PayrollHomePage from "./pages/organizations/payroll/payroll-home";
import LeftPaneComponent from "./components/leftpane/leftpane.component";
import { useMemo } from "react";

export const AuthenticatedRoutes = () => {
  const navigate = useNavigate();

  const onIdle = () => {
    navigate("/?logout=1");
  };

  const { pathname } = useLocation();

  const orgId = useMemo(() => {
    const matches = pathname.match(/(organizations\/)\d+/);
    return matches?.[0].replace("organizations/", "");
  }, [pathname]);

  return (
    <IdleTimerProvider timeout={1000 * 25 * 60} onIdle={onIdle}>
      <div className="divFlex">
        {orgId && <LeftPaneComponent />}
        <Routes>
          <Route path="/home" element={<HomePage />} />
          <Route path="/organizations/*" element={<OrganizationRoutes />} />
        </Routes>
        <OrganizationRoutes />
      </div>
    </IdleTimerProvider>
  );
};

const OrganizationRoutes = () => {
  const employeeRoutes = (
    <RequireEmployeeAccess>
      <Routes>
        <Route path="/" element={<TimeSheetsHomePage />} />
      </Routes>
    </RequireEmployeeAccess>
  );
  const orgAdminRoutes = (
    <RequireOrgAdminAccess>
      <Routes>
        <Route path="/:id/payroll" element={<PayrollHomePage />} />
        <Route path="/:id/products" element={<ProductsHomePage />} />
        <Route path="/:id/invoices/create" element={<AddInvoice />} />
        <Route path="/:id/invoices" element={<InvoicesHomePage />} />

        <Route path="/:id/rewards" element={<RewardsHomePage />} />
        <Route path="/:id/settings" element={<OrganizationSettings />} />
        <Route
          path="/:id/investments"
          element={<OrganizationInvestmentsHomePage />}
        />
        <Route path="/:id/sales" element={<OrganizationSalesHomePage />} />
        <Route
          path="/:id/employees"
          element={<OrganizationEmployeesHomePage />}
        />
        <Route
          path="/:id/expenses"
          element={<OrganizationExpensesHomePage />}
        />
      </Routes>
    </RequireOrgAdminAccess>
  );

  return (
    <Routes>
      <Route
        path="/organizations/:id/reimbursements"
        element={<ReimbursementsHomePage />}
      />
      <Route path="/organizations/:id/timesheets" element={employeeRoutes} />
      <Route path="/organizations/:id" element={<OrganizationHomePage />} />
      <Route path="/organizations/*" element={orgAdminRoutes} />
    </Routes>
  );
};
