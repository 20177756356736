import {
  Checkbox,
  DatePicker,
  Descriptions,
  Flex,
  Input,
  Modal,
  Select,
} from "antd";
import Title from "antd/es/typography/Title";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useGetOrganizationEmployeesById } from "../../../api/api.get";
import { useUpdateEmployee } from "../../../api/api.update";
import ErrorPanel from "../../../components/error.panel.component";

export default function ViewExpenseModal({
  open,
  onCancel,
  empId,
}: {
  open: boolean;
  onCancel: () => void;
  empId: string;
}) {
  const { id: orgId } = useParams();

  const { data: employeeData } = useGetOrganizationEmployeesById(orgId, empId);
  const { mutateAsync } = useUpdateEmployee(orgId, empId);

  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    if (employeeData) {
      const _items: any[] = [];
      _items.push({
        key: "1",
        label: "Role",
        children: employeeData.type,
      });
      _items.push({
        key: "2",
        label: "Email",
        children: employeeData.email,
      });
      _items.push({
        key: "3",
        label: "Contractor/Salaried",
        children: employeeData.employmentType,
      });
      _items.push({
        key: "4",
        label: "Start Date",
        children: employeeData.employmentStartDate
          ? moment(employeeData.employmentStartDate).format("DD MMM YYYY")
          : "",
      });
      setItems(_items);
    }
  }, [employeeData]);

  const formik = useFormik({
    initialValues: {
      employmentType: employeeData?.employmentType ?? "salaried",
      salary: employeeData?.salary ?? 0,
      employmentEndDate: null,
      endEmployment: false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      salary: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid salary")
        .required("Please enter salary"),
      endEmployment: Yup.boolean(),
      employmentEndDate: Yup.string().when("endEmployment", {
        is: true,
        then: (schema) => schema.required("Please enter end date"),
      }),
    }),
    onSubmit: async (values) => {
      mutateAsync(values)
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch(console.error);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        open={open}
        onOk={formik.submitForm}
        okText="Update"
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
      >
        <Flex gap={30} vertical>
          <Descriptions
            layout="vertical"
            title="Edit Employee"
            bordered
            items={items}
          />

          <div>
            <Select
              value={formik.values.employmentType}
              style={{ width: 120 }}
              onChange={(value) =>
                formik.setFieldValue("employmentType", value)
              }
              options={[
                { value: "salaried", label: "Salaried" },
                { value: "hourly", label: "Hourly" },
              ]}
            />
          </div>

          <div>
            <strong>
              {formik.values.employmentType === "salaried"
                ? "Annual Salary"
                : "Hourly Salary"}
            </strong>
            <Input placeholder="salary" {...formik.getFieldProps("salary")} />
            <ErrorPanel message={formik.errors.salary} />
          </div>

          <div className="divSpread divAlignItemsOnly">
            <Checkbox
              onChange={(e) =>
                formik.setFieldValue("endEmployment", e.target.checked)
              }
            >
              <span className="errorMsg">End Employment</span>
            </Checkbox>

            {formik.values.endEmployment && (
              <div className="mx-20">
                <DatePicker
                  onChange={(value) =>
                    formik.setFieldValue("employmentEndDate", value)
                  }
                  value={formik.values.employmentEndDate}
                />

                <ErrorPanel message={formik.errors.employmentEndDate} />
              </div>
            )}
          </div>
        </Flex>
      </Modal>
    </form>
  );
}
