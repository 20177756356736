import { Alert, Flex, Input, Modal, Select, Typography } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateReward } from "../../../api/api";
import {
  useGetRewardLevels,
  useGetRewardLevelsById,
  useSearchCustomers,
} from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { getCountryPhoneCode } from "../../../utils/constants";

const { Title } = Typography;
const { Search } = Input;

export default function AddRewardModal({
  currency,
  open,
  onCancel,
  countryCode,
}: {
  currency: string;
  open: boolean;
  onCancel: () => void;
  countryCode?: string;
}) {
  const { id } = useParams();

  const { mutateAsync, error: createError } = useCreateReward(id);

  const { notification, notifySuccess, notifyError } = useNotifications();

  const areaCode = getCountryPhoneCode(countryCode);

  const [rewardsOptions, setRewardsOptions] = useState<any[]>([]);

  const { data: rewardLevelData } = useGetRewardLevels(id);

  useEffect(() => {
    if (rewardLevelData) {
      const options: any[] = [];
      for (const r of rewardLevelData) {
        options.push({
          label: r.name,
          value: r.id,
        });
      }
      setRewardsOptions(options);
    }
  }, [rewardLevelData]);

  const formik = useFormik({
    initialValues: {
      phone: "",
      levelId: undefined,
      levelType: undefined,
      billAmount: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Please add customer phone number"),
      levelId: Yup.string().required("Please select reward type"),
      levelType: Yup.string(),
      billAmount: Yup.string().when("levelType", {
        is: "percent",
        then: (schema) =>
          schema
            .matches(
              /^(0|[1-9]\d*)(\.\d+)?$/,
              "Please enter numbers only. Do not include % or currency symbols",
            )
            .typeError("Please enter valid number.")
            .required("Plese enter bill amount"),
      }),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        phone: `${areaCode}${values.phone}`,
        billAmount: +values.billAmount,
      })
        .then(() => {
          notifySuccess();
          formik.resetForm();
          onCancel();
        })
        .catch((err) => {
          if (err.response.data?.statusCode === 404) {
            notifyError();
          } else {
            notifyError();
          }
        });
    },
  });

  const {
    data: customerSearchData,
    refetch: searchCustomer,
    error,
  } = useSearchCustomers(areaCode + formik.values.phone, false);

  const { data: levelDetails } = useGetRewardLevelsById(
    id,
    formik.values.levelId,
  );

  useEffect(() => {
    if (formik.values.levelId) {
      formik.setFieldValue("levelType", levelDetails?.type);
    }
  }, [formik.values.levelId]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Transaction Reward"
        open={open}
        onOk={formik.submitForm}
        okText={"Add"}
        onCancel={onCancel}
        okButtonProps={{ disabled: !customerSearchData || !!error }}
      >
        <Flex gap={30} vertical>
          <div>
            <p className="infoMsg">Customer phone number</p>

            <Search
              addonBefore={areaCode}
              onSearch={() => searchCustomer()}
              style={{ width: "80%" }}
              {...formik.getFieldProps("phone")}
            />
            <ErrorPanel message={formik.errors.phone} />
          </div>

          {formik.values.phone && error && (
            <Alert
              showIcon
              closable
              type="error"
              description="Customer not found with this phone number."
            />
          )}

          {formik.values.phone && customerSearchData && (
            <Alert
              type="success"
              showIcon
              description={`Customer found : ${
                customerSearchData?.firstName ?? customerSearchData.email
              } ${customerSearchData?.lastName ?? ""}`}
            />
          )}

          {customerSearchData && (
            <>
              <div>
                <Select
                  style={{ minWidth: 200 }}
                  onChange={(value) => formik.setFieldValue("levelId", value)}
                  options={rewardsOptions}
                />
                <ErrorPanel message={formik.errors.levelId} />
              </div>
              {levelDetails && levelDetails.type === "percent" && (
                <div>
                  <p className="infoMsg">Total Bill Amount</p>
                  <Input {...formik.getFieldProps("billAmount")} />
                  <ErrorPanel message={formik.errors.billAmount} />
                </div>
              )}
              <Flex vertical>
                <span className="infoMsg">Rewards Points:</span>
                <Title level={3}>
                  {levelDetails?.type === "percent"
                    ? Math.round(
                        (levelDetails?.value / 100) * +formik.values.billAmount,
                      )
                    : levelDetails?.value}
                </Title>
              </Flex>
            </>
          )}
        </Flex>
      </Modal>
    </form>
  );
}
