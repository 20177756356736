import { LoadingOutlined } from "@ant-design/icons";
import { Avatar, Flex, Input, List, Modal, Spin } from "antd";
import { TextAreaRef } from "antd/es/input/TextArea";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useSearchProductsByBarcode } from "../../api/api.get";
import ErrorPanel from "../../components/error.panel.component";
import { IProduct } from "../../types/types";

export default function AddTransactionModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const [searchText, setSearchText] = useState<string>("");
  const [items, setItems] = useState<IProduct[]>([]);
  const [debouncedSearchText] = useDebounce(searchText, 500);

  const { data, isLoading, error } = useSearchProductsByBarcode(
    id,
    debouncedSearchText,
  );
  const textInputRef = useRef<TextAreaRef>(null);

  useEffect(() => {
    if (textInputRef.current) {
      textInputRef.current?.focus();
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      const _items = [...items];
      _items.push(data);
      setItems(_items);
      setSearchText("");
      textInputRef.current?.focus();
    }
  }, [data]);

  const total = useMemo(() => {
    if (items.length) {
      return items.reduce((acc, item) => acc + (item.price?.value ?? 0), 0);
    }
    return 0;
  }, [items]);

  return (
    <div>
      <Modal
        title=""
        open={open}
        onOk={() => {}}
        onCancel={onCancel}
        okButtonProps={{ loading: isLoading }}
      >
        <Flex gap={20} vertical style={{ padding: 20 }}>
          <div>
            <Input.TextArea
              ref={textInputRef}
              disabled={isLoading}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder="Place cursor here and scan.."
            />
            {error && (
              <ErrorPanel message={"Item not found with name " + searchText} />
            )}
          </div>

          <List
            itemLayout="horizontal"
            dataSource={items}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={item.image} />}
                  title={<a href="https://ant.design">{item.name}</a>}
                  description={"$" + item.price?.value}
                />
              </List.Item>
            )}
          />

          <div>
            <span>Total</span>
            <h1> ${total.toFixed(2)}</h1>
            {isLoading && (
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            )}
          </div>
        </Flex>
      </Modal>
    </div>
  );
}
