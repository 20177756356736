import { useEffect, useRef, useState } from "react";
import { useGetOrganizationById } from "../api/api.get";
import { useParams } from "react-router-dom";
import { getCurrencySymbol } from "../utils/functions";

export function useCurrency() {
  const { id } = useParams();
  const { data } = useGetOrganizationById(id);
  const [currency, setCurrency] = useState<string>();

  useEffect(() => {
    if (data) {
      setCurrency(getCurrencySymbol(data.country));
    }
  }, [data]);

  return { currency };
}
