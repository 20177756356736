import { DatePicker, Flex, Input, Modal, Select } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateOrgExpense } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { reimbursementCategories } from "../../../utils/reimbursements";
import { useCurrency } from "../../../hooks/useCurrency";

export default function AddExpenseModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { currency } = useCurrency();

  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Expense created successfully.",
  });

  const { mutateAsync, isPending } = useCreateOrgExpense(id);

  const formik = useFormik({
    initialValues: {
      category: "",
      amount: "",
      expenseDate: "",
      notes: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Please select a category"),
      expenseDate: Yup.string().required("Please select date"),
      amount: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid amount")
        .required("Please enter amount"),
    }),
    onSubmit: async (values) => {
      mutateAsync({ ...values, amount: +values.amount })
        .then(() => {
          notifySuccess();
          onCancel();
        })
        .catch(() => {
          notifyError();
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Expense"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
        okButtonProps={{ loading: isPending }}
      >
        {notification}
        <Flex gap={30} vertical>
          <div>
            <Select
              showSearch
              style={{ minWidth: 200 }}
              placeholder="Select a category"
              optionFilterProp="label"
              onChange={(value, option) =>
                formik.setFieldValue("category", (option as any).label)
              }
              options={reimbursementCategories}
            />

            <ErrorPanel message={formik.errors.category} />
          </div>

          <div>
            <Input
              prefix={currency}
              placeholder="Amount"
              {...formik.getFieldProps("amount")}
            />
            <ErrorPanel message={formik.errors.amount} />
          </div>

          <div>
            <DatePicker
              onChange={(value) => formik.setFieldValue("expenseDate", value)}
            />

            <ErrorPanel message={formik.errors.expenseDate} />
          </div>

          <div className="mb-40">
            <Input placeholder="Notes" {...formik.getFieldProps("notes")} />
          </div>
        </Flex>
      </Modal>
    </form>
  );
}
