export default function Box({ children }: React.PropsWithChildren) {
  return (
    <div
      className="divColumn p-16 mt-40"
      style={{ border: "1px solid lightgrey", borderRadius: 10 }}
    >
      {children}
    </div>
  );
}
