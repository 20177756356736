import { Alert, Avatar, Button, Checkbox, Input, Layout, Select } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useCreateOrganization } from "../../api/api";
import ErrorPanel from "../../components/error.panel.component";
import { FormRowCol } from "../../components/forms/form.row.component";
import { ROUTES } from "../../utils/constants";

const { Header, Footer, Sider, Content } = Layout;

interface IValues {
  name?: string;
  website?: string;
}

export default function CreateOrg() {
  const [next, setNext] = useState<boolean>(false);
  const [values, setValues] = useState<IValues>();

  return (
    <div className="mt-40">
      <div className={`divCenterAlign divColumn p-32`}>
        {!next && (
          <CreateOrg1
            name={values?.name}
            website={values?.website}
            onSuccess={(values: IValues) => {
              setValues(values);
              setNext(true);
            }}
          />
        )}

        {next && (
          <CreateOrg2
            name={values?.name}
            website={values?.website}
            onBack={() => setNext(false)}
          />
        )}
      </div>
    </div>
  );
}

function CreateOrg1({
  onSuccess,
  name,
  website,
}: {
  onSuccess: (values: IValues) => void;
  name?: string;
  website?: string;
}) {
  const formik = useFormik({
    initialValues: {
      name,
      website,
      websiteDevelopment: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      website: Yup.string().when("websiteDevelopment", {
        is: false,
        then: (schema) => schema.required("Website cannot be empty"),
      }),
    }),
    onSubmit: async (values) => {
      onSuccess(values);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={`divCenterAlign divColumn p-32`}>
          <Content
            className="p-60"
            style={{ minWidth: "40%", boxShadow: "3px 1px 5px 5px #e6ddd6" }}
          >
            <div className="w-100">
              <div className="mb-80">
                <div className="title mb-10">Business</div>
              </div>

              <div className="divCenterAlign my-0">
                <Avatar
                  style={{ width: 56, height: 56 }}
                  src={"/assets/icons/cityscape.png"}
                />
              </div>

              <div className="mt-80">
                <form onSubmit={formik.handleSubmit}>
                  <FormRowCol label="Business Name">
                    <div className="divColumn">
                      <Input {...formik.getFieldProps("name")} />

                      <ErrorPanel message={formik.errors.name} />
                    </div>
                  </FormRowCol>

                  <FormRowCol label="Website">
                    <div className="divColumn">
                      <Input {...formik.getFieldProps("website")} />
                      <ErrorPanel message={formik.errors.website} />
                    </div>

                    <Checkbox {...formik.getFieldProps("websiteDevelopment")}>
                      Our website is under development
                    </Checkbox>
                  </FormRowCol>

                  <FormRowCol label="">
                    <Button type="primary" onClick={formik.submitForm}>
                      Next
                    </Button>
                  </FormRowCol>
                </form>
              </div>
            </div>
          </Content>
        </div>
      </form>
    </div>
  );
}

function CreateOrg2({
  name,
  website,
  onBack,
}: {
  name?: string;
  website?: string;
  onBack: () => void;
}) {
  const { mutateAsync } = useCreateOrganization();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: name!!,
      website: website!!,
      street1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },
    validationSchema: Yup.object({
      name: Yup.string(),
      website: Yup.string(),
      street1: Yup.string().required("Please enter name"),
      city: Yup.string().required("Please enter city"),
      state: Yup.string().required("Please enter state"),
      country: Yup.string().required("Please select a country"),
      zipCode: Yup.string()
        .matches(/^[0-9]+$/, "Zip code or pin code must be digits")
        .required("Please enter zip.")
        .min(5, "Invalid zipcode")
        .max(7, "Invalid zipcode"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      mutateAsync({ ...values, zipCode: +values.zipCode })
        .then(() => {
          navigate(ROUTES.HOME);
        })
        .catch((err) => {
          if (err.response.data.message === "Address is invalid") {
            setErrorMsg(
              "Unable to verify address. Please double check if you have entered full address including house or unit number.",
            );
          } else {
            setErrorMsg("Whoops! Unable to submit data. Please try again.");
          }
        })
        .finally(() => setIsLoading(false));
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={`divCenterAlign divColumn p-32`}>
          <Content
            className="p-60"
            style={{ minWidth: "40%", boxShadow: "3px 1px 5px 5px #e6ddd6" }}
          >
            <div className="w-100">
              <div className="mb-80">
                <div className="title mb-10">Business</div>
              </div>

              <div className="divCenterAlign my-0">
                <Avatar
                  style={{ width: 56, height: 56 }}
                  src={"/assets/icons/cityscape.png"}
                />
              </div>

              <div className="mt-80">
                <form onSubmit={formik.handleSubmit}>
                  <FormRowCol label="Street 1">
                    <Input {...formik.getFieldProps("street1")} />
                    <ErrorPanel message={formik.errors.street1} />
                  </FormRowCol>

                  <FormRowCol label="Street 2">
                    <Input {...formik.getFieldProps("street2")} />
                    <ErrorPanel message={formik.errors.street2} />
                  </FormRowCol>

                  <FormRowCol label="City">
                    <Input {...formik.getFieldProps("city")} />
                    <ErrorPanel message={formik.errors.city} />
                  </FormRowCol>

                  <FormRowCol label="State">
                    <Input {...formik.getFieldProps("state")} />
                    <ErrorPanel message={formik.errors.state} />
                  </FormRowCol>

                  <FormRowCol label="Country">
                    <Select
                      onChange={(value) =>
                        formik.setFieldValue("country", value)
                      }
                      style={{ width: "auto" }}
                      options={[
                        { value: "CAN", label: "Canada" },
                        { value: "IND", label: "India" },
                        { value: "SG", label: "Singapore" },

                        { value: "UK", label: "United Kingdom" },
                        { value: "USA", label: "USA" },
                      ]}
                    />
                    <ErrorPanel message={formik.errors.country} />
                  </FormRowCol>

                  <FormRowCol label="Zipcode">
                    <Input {...formik.getFieldProps("zipCode")} />
                    <ErrorPanel message={formik.errors.zipCode} />
                  </FormRowCol>

                  {errorMsg && (
                    <Alert
                      type="error"
                      message={errorMsg}
                      style={{ width: 300 }}
                    />
                  )}

                  <FormRowCol label="">
                    <div className="divSpread">
                      <Button onClick={() => onBack()}>Back</Button>
                      <Button
                        type="primary"
                        onClick={formik.submitForm}
                        loading={isLoading}
                      >
                        Submit
                      </Button>
                    </div>
                  </FormRowCol>
                </form>
              </div>
            </div>
          </Content>
        </div>
      </form>
    </div>
  );
}
