import { ClockCircleOutlined } from "@ant-design/icons";
import { Alert, Divider } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Link, useParams } from "react-router-dom";
import {
  useGetOrganizationById,
  useGetProfileOnOrganization,
  useGetReimbursements,
  useGetTimeSheetsTotal,
} from "../../api/api.get";
import { useLayoutContext } from "../../providers/layout.provider";
import { IProfileDetails } from "../../types/types";
import { getCurrencySymbol } from "../../utils/functions";
import BusinessOwnerSection from "./home/owner-section.component";
import OrganizationDate from "./organization-date";

export default function OrganizationHomePage() {
  const { id: orgId } = useParams();
  const {
    data: profile,
    error,
    isLoading,
  } = useGetProfileOnOrganization(orgId);
  const { isMobile } = useLayoutContext();

  return (
    <div style={{ marginBottom: 100 }} className="p-40 divColumn flex-1">
      {isLoading && (
        <div className="divCenterAlign">
          <ThreeDots />
        </div>
      )}

      {!isLoading && (
        <div className={isMobile ? "divColumn" : "divFlex"}>
          <div style={{ flex: 1 }}>
            {profile && (
              <>
                {profile?.type === "admin" && <BusinessOwnerSection />}

                {profile?.type === "employee" && (
                  <EmployeeSection profile={profile} />
                )}
              </>
            )}

            {error && (
              <Alert
                message="Whoops! Something went wrong. Please try after sometime."
                type="warning"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function EmployeeSection({ profile }: { profile: IProfileDetails }) {
  const { id } = useParams();
  const [date, setDate] = useState(moment());
  const { data: orgData } = useGetOrganizationById(id);

  const currency = useMemo(
    () => getCurrencySymbol(orgData?.country),
    [orgData],
  );

  const { data: timesheetTotal, refetch } = useGetTimeSheetsTotal(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );
  const { data: reimbursements, refetch: refetchReimbursements } =
    useGetReimbursements(
      id,
      date.startOf("month").format("YYYY-MM-DD"),
      date.endOf("month").format("YYYY-MM-DD"),
    );

  useEffect(() => {
    if (date) {
      refetch();
      refetchReimbursements();
    }
  }, [date, refetch, refetchReimbursements]);

  const salary = useMemo(() => {
    if (profile.employmentType === "salaried") {
      return ((profile?.salary ?? 0) / 12).toFixed(2);
    }

    if (timesheetTotal) {
      return timesheetTotal.total * profile.salary;
    }

    return 0;
  }, [profile, timesheetTotal]);

  return (
    <div className="divColumn mb-40 flex-1">
      <div className="divCenterAlign">
        <OrganizationDate onChange={(d) => setDate(moment(d, "MMM YYYY"))} />
      </div>

      {!!profile?.employmentEndDate && (
        <div className="divCenterAlign my-20">
          <Alert
            message={`Your employment ended on ${moment(profile.employmentEndDate).format("Do MMM YYYY")}`}
            type="error"
          />
        </div>
      )}

      <Divider />

      <div className="divCenterAlign divColumn flex-1">
        <div className="mb-40">
          <h3>Gross Monthly Pay</h3>
          <h1>
            {currency}
            {salary}
          </h1>

          <div className="my-20">
            <p>
              Total Annual Salary: {currency} {profile?.salary}
            </p>
          </div>
        </div>

        <Divider />

        {profile.employmentType === "hourly" && (
          <>
            <div className="mb-40" id="clocked-hours-section">
              <ClockCircleOutlined style={{ fontSize: 40 }} />
              <p>Clocked hours this month</p>

              <h1 id="clocked-hours-total">{timesheetTotal?.total ?? 0}</h1>

              <Link to={`/organizations/${id}/timesheets`}>View</Link>
            </div>
            <Divider />
          </>
        )}

        <div className="divColumn divCenterAlign">
          <p>Reimbursements</p>

          <h3>{reimbursements?.length ?? 0}</h3>
          <Link to={`/organizations/${id}/reimbursements`}>View</Link>
        </div>
      </div>
    </div>
  );
}
