import { Button, Card, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDeleteOrgReward } from "../../../api/api.delete";
import {
  useGetOrganizationById,
  useGetRewardLevels,
  useGetRewards,
} from "../../../api/api.get";
import { getCurrencySymbol } from "../../../utils/functions";
import OrganizationDate from "../organization-date";
import AddRewardLevelModal from "./add-reward-level-modal";
import ViewRewardModal from "./view-reward.modal";

const { Title } = Typography;

export default function RewardsHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());

  const { data: orgData } = useGetOrganizationById(id);

  const { data: rewardLevels } = useGetRewardLevels(id);

  const { data: rewards, refetch } = useGetRewards(
    id,
    date.startOf("M").format("YYYY-MM-DD"),
    date.endOf("M").format("YYYY-MM-DD"),
  );

  const [deletableRewardLevelId, setDeletableRewardLevelId] =
    useState<string>();

  const { mutateAsync: deleteAsync } = useDeleteOrgReward(
    id!!,
    deletableRewardLevelId!!,
  );
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [viewableRewardObj, setViewableRewardObj] = useState<any>();

  useEffect(() => {
    if (date) {
      refetch();
    }
  }, [date, refetch]);

  const handleDelete = (row: any) => {
    setDeletableRewardLevelId(row.id);
    deleteAsync(null)
      .then(() => refetch())
      .catch(console.error);
  };

  const handleView = (row: any) => {
    setViewableRewardObj(row);
  };

  const rewardLevelColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "value",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete Reward"
          description="Are you sure to delete this level?"
          onConfirm={() => handleDelete(row)}
          onCancel={() => setDeletableRewardLevelId(undefined)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text" color="red">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const rewardTransactionColumns = [
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Rewards",
      dataIndex: "value",
      key: "value",
    },
    {
      title: "Date",
      key: "created_at",
      render: (row: string) => <span>{moment(row).format("DD MMM YYYY")}</span>,
    },
    {
      title: "",
      key: "operationView",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => handleView(row)}>
          View
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Button
          danger
          type="text"
          color="red"
          onClick={() => handleDelete(row)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div style={{ marginBottom: 100 }} className="divColumn p-40 flex-1">
      <AddRewardLevelModal
        currency={getCurrencySymbol(orgData?.country)}
        open={isAdd}
        onCancel={() => setIsAdd(false)}
      />

      <ViewRewardModal
        open={viewableRewardObj}
        onCancel={() => setViewableRewardObj(undefined)}
        reward={viewableRewardObj}
      />

      <div className="divColumn mt-40">
        <div className="divCenterAlign">
          <OrganizationDate
            onChange={(date: string) => setDate(moment(date, "MMM YYYY"))}
          />
        </div>
        <Title level={3}>Reward Transactions</Title>

        <Table dataSource={rewards} columns={rewardTransactionColumns} />
      </div>

      <div className="divSpread divAlignItemsOnly mt-40">
        <Title level={3}>Reward Levels</Title>

        <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
          Add Level
        </Button>
      </div>

      <Title level={5}>
        You can add any number of levels. These can be used when giving out a
        reward to a customer
      </Title>
      <Table dataSource={rewardLevels} columns={rewardLevelColumns} />
    </div>
  );
}

interface ITileProps {
  title: string;
  id: string;
  admin: boolean;
  path?: string;
}

export function Tile({ title, id, admin, path }: ITileProps) {
  return (
    <Card
      id={id}
      style={{
        width: 300,
        height: 250,
        margin: "2%",
        padding: 3,
        borderRadius: 10,
        boxShadow:
          "0.25rem 0.25rem 0.6rem rgba(0,0,0,0.5), 0 0.5rem 1.125rem rgba(75,0,0,0.05)",
      }}
    >
      <div className="divColumn" style={{ height: "100%" }}>
        <div className="divCenterAlign divColumn">
          <p style={{ fontSize: "x-large", fontWeight: "bold" }}> {title}</p>
        </div>
        <div className="divCenterAlign">
          {!admin && <span className="infoMsg">EMPLOYEE</span>}
          {admin && <span className="infoMsg">OWNER</span>}
        </div>

        <div className="my-20 divCenterAlign">
          {path && <Link to={path}>View</Link>}
        </div>
      </div>
    </Card>
  );
}
