import { PropsWithChildren } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetProfileOnOrganization } from "../api/api.get";
import NoAccessPage from "../pages/no-access.page";
import { useAuthContext } from "../providers/auth.provider";

export function RequireEmployeeAccess(props: PropsWithChildren) {
  const { pathname } = useLocation();

  const orgId = findOrgIdFromPath(pathname);
  const { data: profile } = useGetProfileOnOrganization(orgId);

  if (profile?.type === "employee") {
    return <> {props.children} </>;
  }

  return <NoAccessPage />;
}

export function RequireOrgAdminAccess(props: PropsWithChildren) {
  const { pathname } = useLocation();

  const orgId = findOrgIdFromPath(pathname);

  const { data: profile } = useGetProfileOnOrganization(orgId);

  if (profile?.type === "admin") {
    return <> {props.children} </>;
  }

  return <NoAccessPage />;
}

function findOrgIdFromPath(pathname: string) {
  const route = pathname.replace("/organizations/", "");

  return route.indexOf("/") > 0
    ? route.substring(0, route.indexOf("/"))
    : route.substring(0);
}
