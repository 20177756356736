import { Alert, DatePicker, Flex, Input, Modal, Radio, Select } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useAddEmployeeToOrg } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";

export default function AddExpenseModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();

  const { mutateAsync, error } = useAddEmployeeToOrg(id);

  const formik = useFormik({
    initialValues: {
      email: "",
      startDate: undefined,
      employmentType: "salaried",
      salary: "",
      salaryFrequency: "annual",
    },
    validationSchema: Yup.object({
      salaryFrequency: Yup.string(),
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
          "Please enter valid email address",
        )
        .required("Please enter name"),
      startDate: Yup.string().required("Please select employment start date"),
      employmentType: Yup.string().required("Please select type"),
      salary: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid salary")
        .required("Please enter salary"),
    }),
    onSubmit: async (values) => {
      let salary: number = 0;
      if (values.salaryFrequency === "monthly") {
        salary = 12 * +values.salary;
      } else if (values.salaryFrequency === "biweekly") {
        salary = 26 * +values.salary;
      } else {
        salary = +values.salary;
      }
      // @ts-ignore
      delete values["salaryFrequency"];
      mutateAsync({ ...values, salary })
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch(console.error);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Employee"
        open={open}
        onOk={formik.submitForm}
        okText="Add"
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
      >
        <Flex gap={30} vertical style={{ padding: 20 }}>
          <div>
            <Input placeholder="email" {...formik.getFieldProps("email")} />
            <ErrorPanel message={formik.errors.email} />
          </div>

          <div>
            <DatePicker
              placeholder="Select start date"
              onChange={(value) => formik.setFieldValue("startDate", value)}
              value={formik.values.startDate}
            />

            <ErrorPanel message={formik.errors.startDate} />
          </div>

          <div>
            {/**
             * Don't make this as select. Cypress test can't recognize the items and its a nightmare.
             */}
            <Radio.Group
              onChange={(e) =>
                formik.setFieldValue("employmentType", e.target.value)
              }
              value={formik.values.employmentType}
            >
              <Radio value={"salaried"}>Salaried</Radio>
              <Radio value={"hourly"}>Hourly</Radio>
            </Radio.Group>
          </div>

          <div>
            <div className="divSpread divAlignItemsOnly">
              <h4 className="divCenterAlign">
                {formik.values.employmentType === "salaried"
                  ? "Salary"
                  : "Hourly Rate"}
              </h4>

              {formik.values.employmentType === "salaried" && (
                <Radio.Group
                  onChange={(e) =>
                    formik.setFieldValue("salaryFrequency", e.target.value)
                  }
                  value={formik.values.salaryFrequency}
                >
                  <Radio value={"biweekly"}>Bi-weekly</Radio>
                  <Radio value={"monthly"}>Monthly</Radio>
                  <Radio value={"annual"}>Annual</Radio>
                </Radio.Group>
              )}
            </div>

            <Input placeholder="salary" {...formik.getFieldProps("salary")} />
            <ErrorPanel message={formik.errors.salary} />
          </div>

          {error && (
            <Alert
              message={
                error.response.data?.message ??
                "Whoops! Something went wrong. Please try again."
              }
              type="error"
            />
          )}
        </Flex>
      </Modal>
    </form>
  );
}
