import {
  Alert,
  Button,
  Card,
  Descriptions,
  Flex,
  Popconfirm,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useDeleteOrgInvestment,
  useDeletePayrollCycle,
  useDeletePayrollSchedule,
} from "../../../api/api.delete";
import {
  useGetOrganizationEmployees,
  useGetOrganizationInvestments,
  useGetPayrollCycles,
  useGetPayrolls,
  useGetPayrollSchedule,
} from "../../../api/api.get";
import OrganizationDate from "../organization-date";
import OrganizationDetailsSection, {
  OrganizationName,
} from "../organization-details.section";
import { message, Space } from "antd";
import {
  IOrganizationInvestment,
  IPayrollCycle,
  IPayrollSchedule,
} from "../../../types/types";
import AddPayrollScheduleModal from "./add-payroll-schedule-modal";
import { useCreatePayroll } from "../../../api/api";
import { capitalize } from "../../../utils/functions";

const { Title } = Typography;

export default function PayrollHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());

  const { data: schedule, refetch } = useGetPayrollSchedule(id);

  const { data: cycles } = useGetPayrollCycles(id);

  const { data: employees } = useGetOrganizationEmployees(id);

  const [confirmDeleteSchedule, setConfirmDeleteSchedule] =
    useState<boolean>(false);

  const [selectedPayrollCycleId, setSelectedPayrollCycleId] =
    useState<string>();

  const { data: payrolls } = useGetPayrolls(id, selectedPayrollCycleId);

  const { mutateAsync: deleteAsync } = useDeletePayrollSchedule(
    id!!,
    schedule?.id!!,
  );

  const { mutateAsync: createPayrollCycleAsync, error } = useCreatePayroll(
    id!!,
  );

  const { mutateAsync: deletePayrollCycleAsync } = useDeletePayrollCycle(
    id!!,
    selectedPayrollCycleId!!,
  );

  const [isAdd, setIsAdd] = useState<boolean>(false);

  const [deletableSchedule, setDeletableSchedule] =
    useState<IPayrollSchedule>();

  const handleView = (row: any) => {
    window.alert(JSON.stringify(row));
    setDeletableSchedule(row);
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
  }, [date]);

  const handleDelete = () => {
    deleteAsync(null)
      .then(() => {
        message.success("Schedule successfully deleted.");
        refetch();
      })
      .catch(console.error);
  };

  const handleCreatePayrollCycle = () => {
    createPayrollCycleAsync({}).catch(console.error);
  };

  const handleDeletePayrollCycle = () => {
    deletePayrollCycleAsync({}).catch(console.error);
  };

  const columns = [
    {
      title: "Employee Id",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Salary",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure to delete this investment?"
          onConfirm={() => handleDelete()}
          onCancel={() => setConfirmDeleteSchedule(false)}
        >
          <Button danger type="text" color="red">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const cycleItems = useMemo(() => {
    if (cycles) {
      return cycles.map((cycle: IPayrollCycle) => {
        return {
          label: `${cycle.startDate} to ${cycle.endDate}`,
          value: cycle.id,
        };
      });
    }
  }, [cycles]);

  return (
    <div style={{ marginBottom: 100 }} className="body divColumn p-40 flex-1">
      <AddPayrollScheduleModal open={isAdd} onCancel={() => setIsAdd(false)} />

      <div className="divRight mb-0">
        <div className="divRight mb-20">
          {!schedule && (
            <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
              Add Payroll Schedule
            </Button>
          )}
          {schedule && (
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this schedule?"
              onConfirm={() => handleDelete()}
            >
              <Button danger type="text" color="red">
                Delete Schedule
              </Button>
            </Popconfirm>
          )}
        </div>
      </div>

      {schedule && (
        <Descriptions
          title="Payroll Schedule Details"
          bordered
          items={[
            {
              key: "1",
              label: "Schedule",
              children: <div>{capitalize(schedule?.schedule)}</div>,
            },
            {
              key: "2",
              label: "Start Date",
              children: schedule?.startDate,
            },
          ]}
        />
      )}

      {schedule && (
        <div className="divSpread my-40">
          <Flex gap={10}>
            <Select
              showSearch
              style={{ minWidth: 240 }}
              placeholder="Select Payroll Cycle"
              optionFilterProp="label"
              onChange={(value, option) => setSelectedPayrollCycleId(value)}
              options={cycleItems}
            />

            {selectedPayrollCycleId && (
              <Button type="link" danger onClick={handleDeletePayrollCycle}>
                Delete this cycle
              </Button>
            )}
          </Flex>

          <Tooltip title="Add employees to run payroll">
            <Button
              disabled={employees?.length === 1}
              type="primary"
              onClick={handleCreatePayrollCycle}
            >
              Run Payroll
            </Button>
          </Tooltip>
        </div>
      )}

      {error && (
        <div className="divCenterAlign">
          <Alert
            closable
            message={error?.response.data.message ?? "Error running payroll"}
            type="error"
          />
        </div>
      )}

      {cycles && cycles.length > 0 && (
        <Table dataSource={payrolls} columns={columns} />
      )}

      {!schedule && (
        <div className="divCenterAlign">
          <Alert message="Please add your payroll schedule" type="warning" />
        </div>
      )}
    </div>
  );
}
