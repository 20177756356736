import {
  BankOutlined,
  PhoneOutlined,
  SolutionOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useGetProfileOnOrganization } from "../../api/api.get";
import { capitalize } from "../../utils/functions";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

export default function LeftPaneComponent() {
  const { pathname } = useLocation();
  const isPhase2Enabled = useFeatureIsOn("enable-business-phase2");

  const orgId = useMemo(() => {
    const matches = pathname.match(/(organizations\/)\d+/);
    return matches?.[0].replace("organizations/", "");
  }, [pathname]);

  const { data: profile } = useGetProfileOnOrganization(orgId);

  const phase2Items = isPhase2Enabled
    ? [
        {
          key: "2.1",
          icon: (
            <Link to={`/organizations/${orgId}/products`}>
              <img
                src={"/assets/icons/food-other.svg"}
                width={20}
                alt="products"
              />
            </Link>
          ),
          label: "Products",
        },
        {
          key: "2.2",
          icon: (
            <Link to={`/organizations/${orgId}/invoices`}>
              <img src={"/assets/icons/list.png"} width={20} alt="invoices" />
            </Link>
          ),
          label: "Invoices",
        },
      ]
    : [];

  const items: any = useMemo(() => {
    if (profile) {
      const defaultItem = [
        {
          key: "1",
          icon: (
            <Link to={`/organizations/${orgId}`}>
              <img src={"/assets/icons/house.svg"} width={20} alt="home" />
            </Link>
          ),
          label: capitalize("home"),
        },
      ];
      if (profile?.type === "admin") {
        return [
          ...defaultItem,
          ...phase2Items,
          {
            key: "2",
            icon: (
              <Link to={`/organizations/${orgId}/sales`}>
                <img src={"/assets/icons/profits.svg"} width={20} alt="sales" />
              </Link>
            ),
            label: "Orders",
          },
          {
            key: "3",
            icon: (
              <Link to={`/organizations/${orgId}/payroll`}>
                <img
                  src={"/assets/icons/banknote.svg"}
                  width={20}
                  alt="payroll"
                />
              </Link>
            ),
            label: "Payroll",
          },
          {
            key: "4",
            icon: (
              <Link to={`/organizations/${orgId}/expenses`}>
                <img
                  src={"/assets/icons/money.png"}
                  width={20}
                  alt="expenses"
                />
              </Link>
            ),

            label: "Expenses",
          },
          {
            key: "5",
            icon: (
              <Link to={`/organizations/${orgId}/investments`}>
                <img
                  src={"/assets/icons/charts.png"}
                  width={20}
                  alt="investments"
                />
              </Link>
            ),
            label: "Investments",
          },
          {
            key: "6",
            icon: (
              <Link to={`/organizations/${orgId}/employees`}>
                <img
                  src={"/assets/icons/groups.svg"}
                  width={20}
                  alt="employees"
                />
              </Link>
            ),
            label: "Employees",
          },
          {
            key: "7",
            icon: (
              <Link to={`/organizations/${orgId}/rewards`}>
                <StarOutlined />
              </Link>
            ),
            label: "Rewards",
          },

          {
            key: "8",
            icon: (
              <Link to={`/organizations/${orgId}/reimbursements`}>
                <img
                  src={"/assets/icons/scan/receipt.png"}
                  width={20}
                  alt="reimbursements"
                />
              </Link>
            ),
            label: "Reimbursements",
          },
          {
            key: "9",
            icon: (
              <img
                src={"/assets/icons/settings.svg"}
                width={20}
                alt="settings"
              />
            ),
            label: "Settings",
            children: [
              {
                key: "31",
                label: "Organization",
                icon: (
                  <Link to={`/organizations/${orgId}/settings`}>
                    <BankOutlined />
                  </Link>
                ),
              },
            ],
          },
          {
            key: "10",
            icon: (
              <a
                href="https://hub.expensehut.com/support"
                target="_blank"
                rel="noreferrer"
              >
                <PhoneOutlined />
              </a>
            ),
            label: "Support",
          },
        ];
      } else if (profile?.type === "employee") {
        return [
          ...defaultItem,
          {
            key: "2",
            icon: (
              <Link to={`/organizations/${orgId}/reimbursements`}>
                <img
                  src={"/assets/icons/scan/receipt.png"}
                  width={20}
                  alt="reimbursements"
                />
              </Link>
            ),
            label: "Reimbursements",
          },

          {
            key: "3",
            icon: (
              <a
                href="https://hub.expensehut.com/support"
                target="_blank"
                rel="noreferrer"
              >
                <PhoneOutlined />
              </a>
            ),
            label: "Support",
          },
        ];
      } else {
        return [defaultItem];
      }
    }
  }, [profile, orgId]);

  return <Menu mode="inline" style={{ width: 256 }} items={items} />;
}
