import { Button, DatePicker, Flex, Input, Modal } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useUpdateExpense, useUpdateSale } from "../../../api/api.update";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { IOrganizationExpense } from "../../../types/types";

export default function ViewExpenseModal({
  open,
  onCancel,
  expense,
}: {
  expense?: IOrganizationExpense;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();

  const [changeDateMode, setChangeDateMode] = useState<boolean>(false);
  const { notifySuccess, notification } = useNotifications({
    title: "Success",
    subtitle: "Sale created successfully.",
  });

  const { mutateAsync } = useUpdateExpense(id, expense?.id);

  const formik = useFormik({
    initialValues: {
      category: expense?.category ?? "",
      amount: expense?.amount ?? 0,
      expenseDate: expense?.expenseDate ?? "",
      notes: expense?.notes ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      category: Yup.string().required("Please enter name"),
      expenseDate: Yup.string().required("Please select date"),
      amount: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid salary")
        .required("Please enter amount"),
    }),
    onSubmit: async (values) => {
      mutateAsync({ ...values, amount: +values.amount })
        .then(() => {
          formik.resetForm();
          notifySuccess();
          onCancel();
        })
        .catch(console.error);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {notification}
      <Modal
        title="Expense"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
      >
        <Flex gap={30} vertical style={{ padding: 20 }}>
          <div>
            <Input
              placeholder="Description"
              {...formik.getFieldProps("category")}
            />
            <ErrorPanel message={formik.errors.category} />
          </div>

          <div>
            <strong>Amount</strong>
            <Input placeholder="Amount" {...formik.getFieldProps("amount")} />
            <ErrorPanel message={formik.errors.amount} />
          </div>

          <div className="divColumn">
            <strong>Sale Date</strong>
            <div className="divFlex divAlignItemsOnly">
              {!changeDateMode && (
                <>
                  <span className="mr-10">
                    {moment(formik.values.expenseDate).format("DD MMM YYYY")}
                  </span>
                  <Button type="link" onClick={() => setChangeDateMode(true)}>
                    Change
                  </Button>
                </>
              )}
              {changeDateMode && (
                <DatePicker
                  placeholder="Date"
                  onChange={(value) =>
                    formik.setFieldValue("expenseDate", value)
                  }
                />
              )}
            </div>

            <ErrorPanel message={formik.errors.expenseDate} />
          </div>

          <div>
            <Input placeholder="Notes" {...formik.getFieldProps("notes")} />
          </div>
        </Flex>
      </Modal>
    </form>
  );
}
