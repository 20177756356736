import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { EXHUT_BIZ_LOCAL_STORAGE_TOKEN } from "../utils/constants";

function _useDelete<T1, T2, T3>({
  path,
  keys,
  onSuccess,
  host,
}: {
  host?: string;
  path: string;
  keys: (string | null | undefined)[];
  onSuccess?: () => void;
}) {
  const _host = host ?? process.env.REACT_APP_BE_URL;
  const queryClient = useQueryClient();
  return useMutation<T1, T2, T3>({
    mutationFn: () =>
      axios.delete(`${_host}/${path}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN),
        },
      }),
    mutationKey: keys,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: keys }),
  });
}

export function useDeleteOrgExpense(orgId: string, id: string) {
  return _useDelete({
    path: `organizations/${orgId}/expenses/${id}`,
    keys: ["organizations", orgId, "expenses"],
  });
}

export function useDeletePayrollCycle(orgId: string, id: string) {
  return _useDelete({
    path: `organizations/${orgId}/payroll-cycles/${id}`,
    keys: ["organizations", orgId, "payroll-cycles"],
  });
}

export function useDeleteOrgReimbursement(orgId: string, id: string) {
  return _useDelete({
    path: `organizations/${orgId}/reimbursements/${id}`,
    keys: ["organizations", orgId, "reimbursements"],
  });
}

export function useDeleteReimbursementAttachment(
  orgId?: string,
  rid?: string,
  aid?: string,
) {
  return _useDelete({
    path: `organizations/${orgId}/reimbursements/${rid}/attachments/${aid}`,
    keys: ["organizations", orgId, "reimbursements", rid, "attachments", aid],
  });
}

export function useDeleteOrgReward(orgId: string, id: string) {
  return _useDelete({
    path: `organizations/${orgId}/rewards-level/${id}`,
    keys: ["organizations", orgId, "rewards-level"],
  });
}

export function useDeleteOrgInvestment(orgId: string, id: string) {
  return _useDelete({
    path: `organizations/${orgId}/investments/${id}`,
    keys: ["organizations", orgId, "investments"],
  });
}

export function useDeletePayrollSchedule(orgId: string, id: string) {
  return _useDelete({
    path: `organizations/${orgId}/payroll-schedule/${id}`,
    keys: ["organizations", orgId, "payroll-schedule"],
  });
}

export function useDeleteOrgSales(orgId: string, id: string) {
  return _useDelete({
    path: `organizations/${orgId}/sales/${id}`,
    keys: ["organizations", orgId, "sales"],
  });
}

export function useDeleteOrg(orgId?: string) {
  return _useDelete({
    path: `organizations/${orgId}`,
    keys: ["organizations", orgId],
  });
}

export function useDeleteProduct(orgId?: string, productId?: string) {
  return _useDelete({
    path: `organizations/${orgId}/products/${productId}`,
    keys: ["products", productId],
    host: process.env.REACT_APP_PRODUCTS_URL,
  });
}
