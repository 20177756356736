import { Button, Card, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useGetOrganizationExpenses,
  useGetOrganizationSales,
} from "../../../api/api.get";
import OrganizationDate from "../organization-date";
import OrganizationDetailsSection, {
  OrganizationName,
} from "../organization-details.section";
import AddExpenseModal from "./add-sales-modal";
import { useDeleteOrgSales } from "../../../api/api.delete";
import ViewSalesModal from "./view-sales-modal";
import { IOrganizationEmployee, IOrganizationSale } from "../../../types/types";

const { Title } = Typography;

export default function OrganizationSalesHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());

  const { data: sales, refetch } = useGetOrganizationSales(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [deletableSalesId, setDeletableSalesId] = useState<string>();
  const [viewableSalesObj, setViewableSalesObj] = useState<IOrganizationSale>();

  const { mutateAsync: deleteAsync } = useDeleteOrgSales(
    id!!,
    deletableSalesId!!,
  );

  const handleDelete = (row: any) => {
    setDeletableSalesId(row.id);

    deleteAsync(null)
      .then(() => refetch())
      .catch(console.error);
  };

  const handleView = (row: any) => {
    setViewableSalesObj(row);
  };

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString));
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
  }, [date]);

  const columns = [
    {
      title: "Description",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Sale Date",
      dataIndex: "saleDate",
      key: "saleDate",
      render: (value: any) => (
        <span>{moment(value).format("DD MMM YYYY")}</span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (value: any) => (
        <span>{moment(value).format("DD MMM YYYY")}</span>
      ),
    },
    {
      title: "",
      key: "operationView",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => handleView(row)}>
          Edit
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure to delete this order?"
          onConfirm={() => handleDelete(row)}
          onCancel={() => setDeletableSalesId(undefined)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text" color="red">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="body divColumn mx-40 my-40 flex-1"
    >
      <AddExpenseModal open={isAdd} onCancel={() => setIsAdd(false)} />
      <ViewSalesModal
        open={!!viewableSalesObj}
        onCancel={() => setViewableSalesObj(undefined)}
        sales={viewableSalesObj}
      />
      <div className="divCenterAlign">
        <OrganizationDate onChange={handleDateChange} />
      </div>
      <div className="divSpread divAlignItemsOnly mt-40">
        <Title level={3}>Orders</Title>

        <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
          Create Order
        </Button>
      </div>
      <Table dataSource={sales} columns={columns} />
    </div>
  );
}
