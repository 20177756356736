import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import {
  useAuthenticate,
  useAuthenticateWithSSO,
  usePostLogout,
} from "../api/api";
import ErrorPanel from "../components/error.panel.component";
import LinkComponent from "../components/link.component";
import TermsConditionsComponent from "../components/other/TermsConditionsComponent";
import StatusMessageComponent from "../components/status-message.component";
import { useLayoutContext } from "../providers/layout.provider";
import { IStatusMsg } from "../types/types";
import {
  EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
  EXHUT_BIZ_REDIRECT,
  ROUTES,
} from "../utils/constants";
import { downloadAndLoadGSIScript } from "../utils/lib.helper";
import { Button, Input } from "antd";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useAuthContext } from "../providers/auth.provider";

export default function LoginPage() {
  const { mutateAsync, isPending: isLoading, isError } = useAuthenticate();
  const { mutateAsync: loginWithSSO } = useAuthenticateWithSSO();
  const { mutateAsync: mutateLogout } = usePostLogout();
  const [statusMsg, setStatusMsg] = useState<IStatusMsg | undefined>();
  const { isMobile } = useLayoutContext();
  const { setUser } = useAuthContext();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const isLogout = new URLSearchParams(searchParams).get("logout");
  const isTimeout = new URLSearchParams(searchParams).get("timeout");
  const isRegisterSuccess = new URLSearchParams(searchParams).get("register");
  const isEnabled = useFeatureIsOn("enable-business");

  useEffect(() => {
    if (isLogout || isTimeout) {
      mutateLogout().catch(console.error); // TODO: Do not catch this, butcypress fails in the CI
    }

    if (isTimeout) {
      setStatusMsg({ type: "error", text: "Session timed out." });
    }

    if (isRegisterSuccess) {
      setStatusMsg({
        type: "success",
        text: "We have sent a verification link to your email. Please follow the link to complete registration.",
      });
    }
  }, [
    isLogout,
    mutateLogout,
    isTimeout,
    searchParams,
    setSearchParams,
    isRegisterSuccess,
  ]);

  useEffect(() => {
    downloadAndLoadGSIScript()
      .then(() => {
        (window as any).google.accounts.id.initialize({
          client_id: process.env.REACT_APP_EXHUT_GOOGLE_CLIENT_ID,
          callback: (response: any) => {
            loginWithSSO({ token: response.credential, type: "google" })
              .then((resp) => {
                localStorage.setItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN, resp.sign);
                // console.log("auth success", resp)
                const redirect = localStorage.getItem(EXHUT_BIZ_REDIRECT);
                localStorage.removeItem(EXHUT_BIZ_REDIRECT);
                navigate(redirect ?? ROUTES.HOME, { state: { animate: true } });
              })
              .catch(() => {
                setStatusMsg({
                  type: "error",
                  text: "Error signing in. Please try again.",
                });
              });
          },
        });
        (window as any).google.accounts.id.renderButton(
          document.querySelector(".sso-signin"),
          { theme: "dark", size: "medium" }, // customization attributes
        );
      })
      .catch((err) => console.error(err));
  }, [loginWithSSO, navigate]);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please enter password."),
      email: Yup.string().required("Please enter email."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      mutateAsync(values)
        .then((resp) => {
          localStorage.setItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN, resp.sign);
          const redirect = localStorage.getItem(EXHUT_BIZ_REDIRECT);
          // localStorage.removeItem(EXHUT_BIZ_REDIRECT);
          setUser(resp);
          navigate(redirect ?? ROUTES.HOME, { state: { animate: true } });
        })
        .catch((e) => {
          setStatusMsg({
            type: "error",
            text: "The email or password you have entered is incorrect. Please try again.",
          });
        });
    },
  });

  return (
    <div className={`splitright ${isMobile ? "mobile-splitright" : ""}`}>
      <div
        className={`p-0 ${isMobile ? "divColumn" : "divFlex my-0"}`}
        style={{ backgroundColor: "lightgrey" }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <div className="p-10">
            <StatusMessageComponent
              message={statusMsg}
              onClose={() => setStatusMsg(undefined)}
            />

            {isError && <span className="divCenterAlign errorMsg"></span>}
            {isLoading && <div className="divCenterAlign"></div>}
          </div>

          {isMobile && (
            <div className="divRight mb-20">
              <LinkComponent title="Register" path="/register" />
            </div>
          )}

          <div
            style={{
              borderRadius: 10,
              flex: 1,
            }}
          >
            {isEnabled && (
              <form
                onSubmit={formik.handleSubmit}
                className="divAlignItemsOnly divColumn px-20 pb-40"
              >
                <div style={styles.formColumn as React.CSSProperties}>
                  <strong className="font-larger my-10">Email</strong>

                  <Input {...formik.getFieldProps("email")} />
                </div>

                <div style={styles.formRowMin}>
                  <ErrorPanel message={formik.errors.email} />
                </div>

                <div style={styles.formColumn as React.CSSProperties}>
                  <strong className="font-larger my-10">Password</strong>

                  <Input
                    type="password"
                    {...formik.getFieldProps("password")}
                  />
                </div>

                <div style={styles.formRowMin}>
                  <ErrorPanel message={formik.errors.password} />
                </div>

                <div style={styles.formRow}>
                  <div className="divColumn">
                    <Button
                      type="primary"
                      loading={isLoading}
                      onClick={formik.submitForm}
                      id="login-button"
                    >
                      Sign In
                    </Button>

                    <div className="sso-signin my-20"></div>

                    <div className="divCenterAlign mt-10 mb-20">
                      <a
                        href="https://hub.expensehut.com/forgot-password"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Forgot Password
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            )}

            {!isEnabled && (
              <div className="my-20 divCenterAlign">
                <h2>Coming Soon!</h2>
              </div>
            )}
          </div>
        </div>
      </div>

      <TermsConditionsComponent />
    </div>
  );
}

const styles = {
  formRowMin: {
    marginTop: 0,
    display: "flex",
  },
  formRow: {
    marginTop: 30,
    display: "flex",
  },
  formColumn: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
  },
};
