import { Link } from "react-router-dom";
import { useLayoutContext } from "../providers/layout.provider";

export default function LandingPage() {
  const { isMobile } = useLayoutContext();

  return (
    <div className="body">
      <div className={isMobile ? "mx-60 my-40" : "mx-80 my-40"}>
        <div className={`divRight mb-40`}>
          <Link to={"/register"} className="blue-button">
            Sign Up
          </Link>
        </div>

        <TextToImage
          isMobile={isMobile}
          title={"Run your business at lowest cost!"}
          subtitle={"Focus on your customers & leave the rest to us."}
          even={true}
          imgName={"assets/landingpage/business.png"}
        />

        <hr />

        <CenterItem
          isMobile={isMobile}
          title={"All businesses. All sizes. Any industry."}
          subtitle={
            "Designed for all businesses of all sizes for any industry."
          }
          even={true}
        />
        <hr />

        <ImageToText
          isMobile={isMobile}
          title={"Payroll Lite"}
          subtitle={"Manage and track employee payroll & timesheets"}
          even={true}
          imgName={"assets/landingpage/payroll.png"}
        />
        <hr />

        <TextToImage
          isMobile={isMobile}
          title={"Inventory Management"}
          subtitle={"Track products and sales. Start free!"}
          imgWidth={450}
          subtitleSupIndex={""}
          even={true}
          imgName={"assets/landingpage/inventory.jpg"}
        />
        <hr />

        <ImageToText
          isMobile={isMobile}
          title={"Manage Employees"}
          subtitle={"Manage your personnel"}
          even={true}
          imgName={"assets/landingpage/workers.png"}
        />
        <hr />

        <TextToImage
          title={"Track every dollar from your business"}
          subtitle={
            "Sit back relax while we do the math for expenses & profits"
          }
          imgName={"assets/landingpage/expenses-1.jpg"}
          isMobile={isMobile}
          imgWidth={400}
        />
        <hr />

        <GetTheAppItem />

        <hr />

        <ImageToText
          isMobile={isMobile}
          title={"Rewards"}
          subtitle={"Manage store rewards and customer redemption."}
          subtitleSupIndex={"3"}
          even={true}
          imgName={"assets/landingpage/rewards.jpg"}
          imgWidth={400}
        />
        <hr />

        <TextToImage
          isMobile={isMobile}
          title={"Invoices"}
          subtitle={"Send invoices to customers and track payments."}
          subtitleSupIndex={"1"}
          even={true}
          imgName={"assets/landingpage/receipt.svg"}
        />
        <hr />

        <ImageToText
          isMobile={isMobile}
          title={"Scanner Lite"}
          subtitle={"Use your own barcode scanners to add quick orders"}
          even={true}
          imgName={"assets/landingpage/qrcode.png"}
          imgWidth={200}
          containerStyle={{
            backgroundColor: "#FFFADE",
            padding: 20,
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        />
        <hr />

        <CenterItem
          isMobile={isMobile}
          title={"No Credit Cards. No Trial period."}
          subtitle={
            "Use full servicing platform with no credit cards or trial period."
          }
          even={true}
        />
        <hr />

        <ImageToText
          isMobile={isMobile}
          title={"Weekly reports"}
          subtitle={"Get scheduled reports for sales & expenses each month"}
          even={true}
          imgName={"assets/landingpage/weekly-reports.svg"}
          imgWidth={300}
        />
        <hr />

        <FinalItem />

        <hr />
      </div>
    </div>
  );
}

interface IProps {
  isMobile?: boolean;
  title?: string;
  titleElem?: any;
  subtitle?: string;
  subtitleSupIndex?: string;
  imgName?: string;
  imgWidth?: number;
  even?: any;
  ternary?: any;
  containerStyle?: any;
}

function TextToImage({
  isMobile,
  title,
  titleElem,
  subtitle,
  subtitleSupIndex,
  imgName,
  imgWidth = 300,
  even,
  ternary,
  containerStyle,
}: IProps) {
  return (
    <div
      className={
        isMobile
          ? "divColumn divCenterAlign my-60"
          : `${even ? "divSpreadEven" : "divSpread"} my-60`
      }
      style={containerStyle}
    >
      <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
        {title && (
          <strong style={{ fontSize: 35, fontWeight: "" }}>{title}</strong>
        )}
        {titleElem}

        <div style={{ paddingRight: 0, marginTop: 25 }}>
          <p style={{ color: "darkslategrey" }}>
            {subtitle}
            {subtitleSupIndex && (
              <sup style={{ color: "darkslategray" }}>{subtitleSupIndex}</sup>
            )}
          </p>
        </div>
        {ternary}
      </div>

      {imgName && <img src={imgName} width={imgWidth} alt={imgName} />}
    </div>
  );
}

function ImageToText({
  title,
  subtitle,
  subtitleSupIndex,
  imgName,
  isMobile,
  ternary,
  imgWidth = 300,
  even,
  containerStyle,
}: IProps) {
  if (isMobile) {
    return (
      <TextToImage
        title={title}
        subtitle={subtitle}
        imgName={imgName}
        isMobile={isMobile}
        imgWidth={imgWidth}
        containerStyle={containerStyle}
      />
    );
  }

  return (
    <div className={`divSpreadEven my-60`} style={containerStyle}>
      {imgName && (
        <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
          <img src={imgName} width={imgWidth} alt={"alt-img"} />
        </div>
      )}

      <div className={isMobile ? "mb-40" : "divColumn mb-20 w-40"}>
        <strong style={{ fontSize: 30, fontWeight: "" }}>{title}</strong>
        <div style={{ paddingRight: 32 }}>
          <p style={{ color: "darkslategrey" }}>
            {subtitle}
            {subtitleSupIndex && (
              <sup style={{ color: "darkslategray" }}>{subtitleSupIndex}</sup>
            )}
          </p>
        </div>
        {ternary}
      </div>
    </div>
  );
}

function CenterItem({
  title,
  subtitle,
  imgName,
  ternary,
  isMobile,
  imgWidth = 300,
}: IProps) {
  return (
    <div className={"divColumn divCenterAlign my-80"}>
      <div className={isMobile ? "mb-40" : "mb-20 w-40"}>
        <span style={{ fontSize: 30, fontWeight: "bold" }}>{title}</span>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>{subtitle}</p>
        </div>
        {ternary}
      </div>

      {imgName && (
        <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
          <img
            src={`/assets/landingpage/${imgName}`}
            width={imgWidth}
            alt={imgName}
          />
        </div>
      )}
    </div>
  );
}

function FinalItem({ isMobile }: IProps) {
  return (
    <div
      className={"divColumn divCenterAlign my-40 p-40"}
      style={{ backgroundColor: "#FFDEFE", borderRadius: 30 }}
    >
      <div className={isMobile ? "mb-10" : "mb-10 w-100"}>
        <span style={{ fontSize: 30, fontWeight: "bold" }}>
          Lets start business!
        </span>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>Powered by Expensehut</p>
        </div>
      </div>
    </div>
  );
}

function GetTheAppItem({ isMobile }: IProps) {
  if (isMobile) {
    return (
      <div
        className={"divCenterAlign divColumn my-20 p-20"}
        style={{ backgroundColor: "#DEEFFF", borderRadius: 30 }}
      >
        <div className={isMobile ? "mb-40" : "mb-20 w-50"}>
          <span style={{ fontSize: 30, fontWeight: "bold" }}>Get the App</span>
          <div style={{ paddingRight: 32 }}>
            <p style={{ lineHeight: 1.3 }}>Available in Android and iOS</p>
          </div>

          <div>
            <AndroidAppLink />

            <div className="mx-10">
              <IOSAppLink />
            </div>
          </div>
        </div>

        <img
          style={{ width: 200 }}
          alt="exhut type-less"
          src={"/assets/iphone-exhut.png"}
        />
      </div>
    );
  }
  return (
    <div
      className={"divSpreadEven my-20 p-20"}
      style={{ backgroundColor: "#DEEFFF", borderRadius: 30 }}
    >
      <img
        style={{ width: 200 }}
        alt="exhut type-less"
        src={"/assets/iphone-exhut.png"}
      />

      <div className={"mb-20"}>
        <span style={{ fontSize: 30, fontWeight: "bold" }}>Get the App</span>
        <div style={{ paddingRight: 32 }}>
          <p style={{ lineHeight: 1.3 }}>Available in Android and iOS</p>
        </div>

        <div>
          <AndroidAppLink />

          <div className="mx-10">
            <IOSAppLink />
          </div>
        </div>
      </div>
    </div>
  );
}

export function AndroidAppLink() {
  return (
    <div>
      <a
        href="https://play.google.com/store/apps/details?id=com.snarain.expensehut"
        rel="noreferrer"
        target="_blank"
      >
        <img
          src="assets/google-play-badge.png"
          width="150px"
          alt="android play store"
        />
      </a>
    </div>
  );
}

export function IOSAppLink() {
  return (
    <div>
      <a
        href="https://apps.apple.com/us/app/expense-hut/id1562719499"
        rel="noreferrer"
        target="_blank"
      >
        <img src="assets/apple-store-badge.svg" width="130px" alt="ios" />
      </a>
    </div>
  );
}
