import { Descriptions } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetOrganizationById,
  useGetProfileOnOrganization,
} from "../../api/api.get";
import { capitalize } from "../../utils/functions";
import moment from "moment";

export default function OrganizationDetailsSection() {
  const { id } = useParams();
  const { data: orgData } = useGetOrganizationById(id);

  const { data: profile } = useGetProfileOnOrganization(id);
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    const _items: any[] = [];

    if (orgData) {
      _items.push({
        key: "1",
        label: "Name",
        children: capitalize(orgData.name),
      });
      _items.push({
        key: "2",
        label: "Website",
        children: orgData.website,
      });
    }
    if (profile) {
      _items.push({
        key: "3",
        label: "Role",
        children: capitalize(profile.type),
      });
      _items.push({
        key: "4",
        label: "First Name",
        children: profile.firstName,
      });
      _items.push({
        key: "5",
        label: "Last Name",
        children: profile.lastName,
      });
      _items.push({
        key: "6",
        label: "Country",
        children: orgData?.country,
      });

      if (profile.type === "employee") {
        _items.push({
          key: "7",
          label: "Salaried/Hourly",
          children: profile.employmentType,
        });
        _items.push({
          key: "8",
          label: "Start Date",
          children: moment(profile.employmentStartDate).format("DD MMM YYYY"),
        });
        _items.push({
          key: "9",
          label: "Salary",
          children: profile.salary,
        });
      }

      setItems(_items);
    }
  }, [profile, orgData]);

  return (
    <div className="mx-0">
      <Descriptions title="Organization" bordered items={items} />
    </div>
  );
}

export function OrganizationName() {
  const { id } = useParams();
  const { data: orgData } = useGetOrganizationById(id);
  const [items, setItems] = useState<any[]>([]);
  useEffect(() => {
    const _items: any[] = [];
    if (orgData) {
      _items.push({
        key: "1",
        label: "Name",
        children: capitalize(orgData.name),
      });
      setItems(_items);
    }
  }, [orgData]);
  return (
    <div
      className="mx-0"
      style={{
        backgroundImage:
          "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(172,172,172,1) 0%, rgba(96,96,96,1) 100%)",
        padding: 10,
      }}
    >
      <strong style={{ color: "white" }}>{capitalize(orgData?.name)}</strong>
    </div>
  );
}
