import { UploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Flex,
  Input,
  Modal,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateProduct } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";

export default function AddProductsModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [error, setError] = useState<string>();

  const { notifySuccess, notification } = useNotifications({
    title: "Success",
    subtitle: "Sale created successfully.",
  });

  const { mutateAsync, isPending } = useCreateProduct(id);

  const getBase64 = function (file: any): Promise<string | null> {
    return new Promise((resolve, reject) => {
      if (file) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = function () {
          resolve(fileReader.result as string);
        };
        fileReader.onerror = function (error) {
          reject(error);
        };
      } else {
        return resolve(null);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      price: "",
      saleDate: "",
      externalId: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      description: Yup.string(),
      price: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid price")
        .required("Please enter price"),
      externalId: Yup.string(),
    }),
    onSubmit: async (values) => {
      setError(undefined);
      const base64 = await getBase64(fileList[0]);
      const payload = {
        name: values.name,
        description: values.description,
        price: {
          currency: "USD" as any,
          value: +values.price,
        },
        image: base64,
      };

      mutateAsync(payload)
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch((err) =>
          setError("Whoops! Something went wrong. Please try again."),
        );
    },
  });

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      if (file.size > 10045377) {
        notifySuccess();
      }
      return false;
    },
    fileList,
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {notification}
      <Modal
        title="Add Product"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
        okButtonProps={{ loading: isPending }}
      >
        <Flex gap={30} vertical style={{ padding: 20 }}>
          <div>
            <Input placeholder="Name" {...formik.getFieldProps("name")} />
            <ErrorPanel message={formik.errors.name} />
          </div>
          <div>
            <Input.TextArea
              placeholder="Decription"
              {...formik.getFieldProps("description")}
            />
            <ErrorPanel message={formik.errors.description} />
          </div>

          <div>
            <Input placeholder="Price" {...formik.getFieldProps("price")} />
            <ErrorPanel message={formik.errors.price} />
          </div>

          <Upload {...props} maxCount={1} accept="image/png,image/jpeg">
            <Button disabled={fileList.length > 0} icon={<UploadOutlined />}>
              Upload Image
            </Button>
          </Upload>

          <div>
            <Input
              placeholder="BarCode Id"
              {...formik.getFieldProps("externalId")}
            />
          </div>

          <div>{error && <Alert type="error" message={error} />}</div>
        </Flex>
      </Modal>
    </form>
  );
}
