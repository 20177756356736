export const dateMap = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const EXHUT_BIZ_LOCAL_STORAGE_TOKEN = "exhut-biz-web-token";
export const EXHUT_BIZ_REDIRECT = "exhut-biz-redirect-url";

export enum ROUTES {
  HOME = "/home",
  BASE = "/",
  REGISTER = "/register",
  LOGIN = "/login",
  ADMIN = "/admin",
  jobs = "",
  register = "/register",
  forgotPassword = "/forgot-password",
}

export function getSubscription(entitlement: string | number | undefined) {
  if (entitlement) {
    switch (entitlement) {
      case 0:
        return "TRIAL";
      case 1:
        return "BASIC";
      case 2:
        return "STANDARD";
      case 3:
        return "PREMIUM";
      case 10:
        return "UNLIMITED";
    }
  } else {
    return "TRIAL";
  }
}

enum Countries {
  AUS = "AUS",
  BW = "BW",
  CAN = "CAN",
  CHN = "CHN",
  IND = "IND",
  SGP = "SGP",
  UK = "UK",
  USA = "USA",
}

export const countries = ["AUS", "BW", "CAN", "CHN", "IND", "SGP", "UK", "USA"];

export function getCountryPhoneCode(country?: string) {
  switch (country) {
    case Countries.AUS:
      return "+61";
    case Countries.BW:
      return "+267";
    case Countries.CAN:
      return "+91";
    case Countries.CHN:
      return "+86";
    case Countries.IND:
      return "+91";
    case Countries.SGP:
      return "+65";
    case Countries.UK:
      return "+44";
    case Countries.USA:
      return "+1";
    default:
      return "";
  }
}
