import { Card } from "antd";
import { Link } from "react-router-dom";

interface ITileProps {
  title: string;
  id: string;
  admin: boolean;
}

export default function OrganizationTile({ title, id, admin }: ITileProps) {
  return (
    <Card
      id={id}
      style={{
        width: 300,
        minHeight: 250,
        margin: "2%",
        padding: 3,
        borderRadius: 20,
        boxShadow:
          "0.25rem 0.25rem 0.6rem rgba(0,0,0,0.5), 0 0.5rem 1.125rem rgba(75,0,0,0.05)",
      }}
    >
      <div className="divColumn" style={{ height: "100%" }}>
        <div className="divCenterAlign divColumn">
          <img src="/assets/icons/cityscape.png" width={30} />
          <p style={{ fontSize: "xx-large", fontWeight: "bold" }}> {title}</p>
        </div>
        <div className="divCenterAlign">
          {!admin && <span className="infoMsg">EMPLOYEE</span>}
          {admin && <span className="infoMsg">OWNER</span>}
        </div>

        <div className="my-20 divCenterAlign">
          <Link to={`/organizations/${id}`}>View</Link>
        </div>
      </div>
    </Card>
  );
}
